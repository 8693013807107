import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Gunmeh`;

// BODY
const bodyGunmehImage = `${imagePath}/chassis.png`;

// ARMS
// LEFT
const leftArmImage = `${imagePath}/R_ARM/drill.png`;
// RIGHT
const rightArmImage = `${imagePath}/L_ARM/l_arm.png`;

// LEGS
const legsImage = `${imagePath}/LEGS/legs.png`;

// GLOWS
const glowDrillImage = `${imagePath}/ACCESSORY/drill_glow.png`;
const glowEyesImage = `${imagePath}/ACCESSORY/eyes_glow.png`;
const glowKaminaImage = `${imagePath}/ACCESSORY/kamina.png`;
const glowKaminaGlintImage = `${imagePath}/ACCESSORY/kamina_glint.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/GUNMEH_0.png`;
const core2Image = `${imagePath}/GUNMEH_1.png`;
const core3Image = `${imagePath}/GUNMEH_2.png`;

const artCredit = {
    prefixText: "Created by ",
    title: "Fliren",
    url: "https://linktr.ee/fliren?utm_source=linktree_profile_share&ltsid=1a59ce3f-a79f-40e0-8035-d8fdd74aa3c4",
};

const regularShadow = {
    size: shadowTypes.LARGE,
    bottom: "51px",
    right: "34px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: artCredit,
        },
        {
            src: core2Image,
            originalArtCredit: artCredit,
        },
        {
            src: core3Image,
            originalArtCredit: artCredit,
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "KAMINA GLINT",
            foremostSrc: glowKaminaGlintImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GUNMEH",
            src: bodyGunmehImage,
            shadowDetails: regularShadow,
            originalArtCredit: artCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "DRILL",
                backSrc: leftArmImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FIST",
            src: rightArmImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "LEGS",
            src: legsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "DRILL",
            src: glowDrillImage,
        },
        {
            value: 1,
            label: "EYES",
            frontSrc: glowEyesImage,
        },
        {
            value: 2,
            label: "KAMINA",
            frontSrc: glowKaminaImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ARMS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "EXTRA",
            value: "head",
        },
        {
            label: "L ARM",
            value: "weapon",
        },
        {
            label: "R ARM",
            value: "secondaryWeapon",
        },
        {
            label: "LEGS",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "EXTRA",
        "chassis": "CHASSIS",
        "rearMount": "LEGS",
        "weapon": "L ARM",
        "secondaryWeapon": "R ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
