import BaseURL from "./images/Hosting-BaseURL";
import { removeTintValues } from "./common/settings";
import { shadowTypes } from "../styles/UnitShadow";

// BODY
const bodyMasculineImage = `${BaseURL}/Heroes/Harvester/body/Body - M.png`;
const bodyFeminineImage = `${BaseURL}/Heroes/Harvester/body/Body - F.png`;
const bodyLupineMasculineImage = `${BaseURL}/Heroes/Harvester/body/Body - M - Lupine.png`;
const bodyLupineFeminineImage = `${BaseURL}/Heroes/Harvester/body/Body - F - Lupine.png`;
const bodyFelineMasculineImage = `${BaseURL}/Heroes/Harvester/body/Body - M - Feline.png`;
const bodyFelineFeminineImage = `${BaseURL}/Heroes/Harvester/body/Body - F - Feline.png`;
const bodyReptileMasculineImage = `${BaseURL}/Heroes/Harvester/body/Body - M - Reptile.png`;
const bodyReptileFeminineImage = `${BaseURL}/Heroes/Harvester/body/Body - F - Reptile.png`;

// FACE
const faceMasculineImage = `${BaseURL}/Heroes/Harvester/face/Face - M.png`;
const faceFeminineImage = `${BaseURL}/Heroes/Harvester/face/Face - F.png`;
const faceElvishMasculineImage = `${BaseURL}/Heroes/Harvester/face/Face - Elvish M.png`;
const faceElvishFeminineImage = `${BaseURL}/Heroes/Harvester/face/Face - Elvish F.png`;
// const faceElvishOverImage = `${BaseURL}/Heroes/Seer/faces/Face - Elvish M F Over.png`;
const faceFennecImage = `${BaseURL}/Heroes/Harvester/face/Face - Fennec.png`;
const faceFrogImage = `${BaseURL}/Heroes/Harvester/face/Face - Frog.png`;
const faceLepusImage = `${BaseURL}/Heroes/Harvester/face/Face - Lepus.png`;
const faceLupineImage = `${BaseURL}/Heroes/Harvester/face/Face - Lupine.png`;
const faceBarnOwlImage = `${BaseURL}/Heroes/Harvester/face/Face - Barn Owl.png`;
const faceHornedOwlImage = `${BaseURL}/Heroes/Harvester/face/Face - Horned Owl.png`;
const faceReptileImage = `${BaseURL}/Heroes/Harvester/face/Face - Lizard.png`;
const faceVulpineImage = `${BaseURL}/Heroes/Harvester/face/Face - Vulpine.png`;
const faceXixo1Image = `${BaseURL}/Heroes/Harvester/face/Face - Xixo 1.png`;
const faceXixo2Image = `${BaseURL}/Heroes/Harvester/face/Face - Xixo 2.png`;
const faceXixo3Image = `${BaseURL}/Heroes/Harvester/face/Face - Xixo 3.png`;
const faceXixo4Image = `${BaseURL}/Heroes/Harvester/face/Face - Xixo 4.png`;
const faceCatEarsImage = `${BaseURL}/Heroes/Harvester/face/Head - Cat Ears.png`;
const faceCatEarsUnderImage = `${BaseURL}/Heroes/Harvester/face/Head - Cat Ears Under.png`;

// EYES
// const eyeGlowImage = `${BaseURL}/Heroes/Seer/glows/Glow - Eye.png`;

// HAIR
const hairAfroImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Afro.png`;
const hairPonytailUnderImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Bobbed Ponytail Under.png`;
const hairBobbedImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Bobbed.png`;
const hairFauxhawkImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Faux Hawk.png`;
const hairFlowingLocksImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Flowing Locks.png`;
const hairGuileImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Guile.png`;
const hairHiroProtagonistImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Hiro Protagonist.png`;
const hairLongBraidUnderImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Long Braid Under.png`;
const hairLongElvishPonytailImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Long Elvish Ponytail.png`;
const hairLongElvishImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Long Elvish.png`;
const hairLongUnderImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Long Hair Under.png`;
const hairLongWavyPartedImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Long Wavy Parted.png`;
const hairMediumBackImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Medium Back.png`;
const hairShortPartedImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Short Parted.png`;
const hairTousledImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Short Tousled.png`;
const hairTheMajorImage = `${BaseURL}/Heroes/Harvester/hair/Hair - The Major + Ponytail.png`;
const hairTrogg1UnderImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Throgg 1 Under.png`;
const hairTroggLongImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Trogg 1.png`;
const hairTroggMediumImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Trogg 2.png`;
const hairWildImage = `${BaseURL}/Heroes/Harvester/hair/Hair - Wild.png`;

// FACIAL HAIR
const facialHairBubImage = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Bub.png`;
const facialHairHandlebarBubImage = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Handlebar Bub.png`;
const facialHairHandlebarStacheImage = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Handlebar Moustache.png`;
const facialHairShortBeard1Image = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Short Beard 1.png`;
const facialHairShortBeard2Image = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Short Beard 2.png`;
const facialHairMediumBeard1Image = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Medim Beard 2.png`;
const facialHairMediumBeard2Image = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Medim Beard Orcish.png`;
const facialHairLongBeardImage = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Long Beard.png`;
const facialHairLongGoateeImage = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Long Goatee.png`;
const facialHairLongMoustacheImage = `${BaseURL}/Heroes/Harvester/hair/facial/Facial Hair - Long Moustache Beard.png`;

// HEAD
const headAntlersImage = `${BaseURL}/Heroes/Harvester/head/Head - Antlers.png`;
const headHorns1Image = `${BaseURL}/Heroes/Harvester/head/Head - Horns 1.png`;
const headHorns2Image = `${BaseURL}/Heroes/Harvester/head/Head - Horns 2.png`;
const headHorns3Image = `${BaseURL}/Heroes/Harvester/head/Head - Horns 3.png`;
const headHarvesterVeilImage = `${BaseURL}/Heroes/Harvester/head/Head - Harvester Veil.png`;
const headHarvesterVeilUnderImage = `${BaseURL}/Heroes/Harvester/head/Head - Harvester Veil Under.png`;
const headInfernalImage = `${BaseURL}/Heroes/Harvester/head/Head - Infernal.png`;
const headCourtMagisterHoodImage = `${BaseURL}/Heroes/Harvester/head/Head - Magister Hood.png`;
const headPaintingGuardianImage = `${BaseURL}/Heroes/Harvester/head/Head - Painting Guardian.png`;
const headPsychopompImage = `${BaseURL}/Heroes/Harvester/head/Head - Psychopomp Hood.png`;
const headKomusoImage = `${BaseURL}/Heroes/Harvester/head/Head - Komuso.png`;
const headRoningasaImage = `${BaseURL}/Heroes/Harvester/head/Head - Roningasa.png`;
const headSandokasaImage = `${BaseURL}/Heroes/Harvester/head/Head - Sandokasa.png`;
const headSolsticeHoodImage = `${BaseURL}/Heroes/Harvester/head/Head - Solstice Hood.png`;
const headWeaverHoodImage = `${BaseURL}/Heroes/Harvester/head/Head - Weaver Hood.png`;
const headWeaverHoodUnderImage = `${BaseURL}/Heroes/Harvester/head/Head - Weaver Hood Under.png`;

// CHEST
const chestHarvesterImage = `${BaseURL}/Heroes/Harvester/chest/Outfit - Harvester.png`;
const chestXixoHarvesterImage = `${BaseURL}/Heroes/Harvester/chest/Outfit - Harvester - Xixo.png`;
const chestPsychopompImage = `${BaseURL}/Heroes/Harvester/chest/Outfit - Psychopomp.png`;
const chestSolsticeImage = `${BaseURL}/Heroes/Harvester/chest/Outfit - Solstice.png`;

// LEFT HAND
const leftHandScytheUpImage = `${BaseURL}/Heroes/Harvester/weapons/left/Left Weapon - Scythe Upright.png`;
const leftHandScytheDownImage = `${BaseURL}/Heroes/Harvester/weapons/left/Left Weapon - Scythe Down.png`;
const leftHandQuillImage = `${BaseURL}/Heroes/Harvester/weapons/left/Left Weapon - Quill.png`;

// RIGHT HAND
const rightHandPetalsImage = `${BaseURL}/Heroes/Harvester/weapons/right/Right Weapon - Petals.png`;
const rightHandVinesImage = `${BaseURL}/Heroes/Harvester/weapons/right/Right Weapon - Vines.png`;
const rightHandBookImage = `${BaseURL}/Heroes/Harvester/weapons/right/Right Weapon - Book.png`;
const rightHandHourglassImage = `${BaseURL}/Heroes/Harvester/weapons/right/Right Weapon - Hourglass.png`;

// ACCESSORIES
const accessoryScytheRibbonImage = `${BaseURL}/Heroes/Harvester/accessory/Accessory - Scythe Ribbon.png`;

const previewImage = `${BaseURL}/Heroes/Harvester/preview.png`;

const shadow = {
    size: shadowTypes.SMALL,
    bottom: "79px",
    right: "85px",
};

const defaultSelectionValues = {
    "skinValue": "1",
    "faceValue": "14",
    "eyesValue": "NONE",
    "hairValue": "NONE",
    "facialHairValue": "NONE",
    "headValue": "4",
    "chestValue": "1",
    "legsValue": "NONE",
    "leftArmValue": "NONE",
    "rightArmValue": "NONE",
    "leftWeaponValue": "0",
    "rightWeaponValue": "0",
    "accessory1Value": "0",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const emptySelectionValues = {
    "skinValue": "RANDOM",
    "faceValue": "RANDOM",
    "eyesValue": "NONE",
    "hairValue": "NONE",
    "facialHairValue": "NONE",
    "headValue": "NONE",
    "chestValue": "NONE",
    "legsValue": "NONE",
    "leftArmValue": "NONE",
    "rightArmValue": "NONE",
    "leftWeaponValue": "RANDOM",
    "rightWeaponValue": "RANDOM",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const defaultValues = Object.assign({}, defaultSelectionValues, removeTintValues);

export default {
    previewImg: previewImage,
    isExtraLarge: true,
    defaultValues: JSON.stringify(defaultValues),
    skin: [
        {
            value: 0,
            label: "Masculine",
            src: bodyMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 1,
            label: "Feminine",
            src: bodyFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 2,
            label: "Reptilekin Masculine",
            src: bodyReptileMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 3,
            label: "Reptilekin Feminine",
            src: bodyReptileFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 4,
            label: "Lupine Masculine",
            src: bodyLupineMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 5,
            label: "Lupine Feminine",
            src: bodyLupineFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 6,
            label: "Feline Masculine",
            src: bodyFelineMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 7,
            label: "Feline Feminine",
            src: bodyFelineFeminineImage,
            shadowDetails: shadow,
        },
    ],
    face: [
        {
            value: 0,
            label: "Masculine",
            src: faceMasculineImage,
        },
        {
            value: 1,
            label: "Feminine",
            src: faceFeminineImage,
        },
        {
            value: 2,
            label: "Elven Masculine",
            src: faceElvishMasculineImage,
            // frontSrc: faceElvishOverImage,
        },
        {
            value: 3,
            label: "Elven Feminine",
            src: faceElvishFeminineImage,
            // frontSrc: faceElvishOverImage,
        },
        {
            value: 4,
            label: "Feline Masculine",
            src: faceMasculineImage,
            frontSrc: faceCatEarsImage,
            backSrc: faceCatEarsUnderImage,
        },
        {
            value: 5,
            label: "Feline Feminine",
            src: faceFeminineImage,
            frontSrc: faceCatEarsImage,
            backSrc: faceCatEarsUnderImage,
        },
        {
            value: 6,
            label: "Fennec",
            src: faceFennecImage,
        },
        {
            value: 7,
            label: "Frog",
            src: faceFrogImage,
        },
        {
            value: 8,
            label: "Lepus",
            src: faceLepusImage,
        },
        {
            value: 9,
            label: "Lupine",
            src: faceLupineImage,
        },
        {
            value: 10,
            label: "Barn Owl",
            src: faceBarnOwlImage,
        },
        {
            value: 11,
            label: "Horned Owl",
            src: faceHornedOwlImage,
        },
        {
            value: 12,
            label: "Reptilekin",
            src: faceReptileImage,
        },
        {
            value: 13,
            label: "Vulpine",
            src: faceVulpineImage,
        },
        {
            value: 14,
            label: "Xixo 1",
            src: faceXixo1Image,
        },
        {
            value: 15,
            label: "Xixo 2",
            src: faceXixo2Image,
        },
        {
            value: 16,
            label: "Xixo 3",
            src: faceXixo3Image,
        },
        {
            value: 17,
            label: "Xixo 4",
            src: faceXixo4Image,
        },
    ],
    eyes: [],
    hair: [
        {
            value: 0,
            label: "Short Parted",
            src: hairShortPartedImage,
        },
        {
            value: 1,
            label: "Medium Back",
            src: hairMediumBackImage,
        },
        {
            value: 2,
            label: "Fauxhawk",
            src: hairFauxhawkImage,
        },
        {
            value: 3,
            label: "Wild",
            src: hairWildImage,
        },
        {
            value: 4,
            label: "Wild + Braid",
            src: hairWildImage,
            backSrc: hairLongBraidUnderImage,
        },
        {
            value: 5,
            label: "Afro",
            src: hairAfroImage,
        },
        {
            value: 6,
            label: "Flowing Locks",
            src: hairFlowingLocksImage,
        },
        {
            value: 7,
            label: "Bobbed",
            src: hairBobbedImage,
        },
        {
            value: 8,
            label: "Bobbed + Ponytail",
            src: hairBobbedImage,
            backSrc: hairPonytailUnderImage,
        },
        {
            value: 9,
            label: "Long Elvish",
            src: hairLongElvishImage,
            backSrc: hairLongUnderImage,
        },
        {
            value: 10,
            label: "Long Elvish Braid",
            src: hairLongElvishPonytailImage,
            backSrc: hairLongBraidUnderImage,
        },
        {
            value: 11,
            label: "Long Wavy Parted",
            src: hairLongWavyPartedImage,
            backSrc: hairLongUnderImage,
        },
        {
            value: 12,
            label: "Tousled",
            src: hairTousledImage,
        },
        {
            value: 13,
            label: "Guile",
            src: hairGuileImage,
        },
        {
            value: 14,
            label: "The Major",
            src: hairTheMajorImage,
        },
        {
            value: 15,
            label: "The Major + Ponytail",
            src: hairTheMajorImage,
            backSrc: hairPonytailUnderImage,
        },
        {
            value: 16,
            label: "Hiro Protagonist",
            src: hairHiroProtagonistImage,
        },
        {
            value: 17,
            label: "Trogg Medium",
            src: hairTroggMediumImage,
        },
        {
            value: 18,
            label: "Trogg Long",
            src: hairTroggLongImage,
            backSrc: hairTrogg1UnderImage,
        },
    ],
    facialHair: [
        {
            value: 0,
            label: "Bub",
            src: facialHairBubImage,
        },
        {
            value: 1,
            label: "Handlebar Stache",
            src: facialHairHandlebarStacheImage,
        },
        {
            value: 2,
            label: "Handlebar + Bub",
            src: facialHairHandlebarBubImage,
        },
        {
            value: 3,
            label: "Short Beard 1",
            frontSrc: facialHairShortBeard1Image,
        },
        {
            value: 4,
            label: "Short Beard 2",
            frontSrc: facialHairShortBeard2Image,
        },
        {
            value: 5,
            label: "Medium Beard 1",
            src: facialHairMediumBeard1Image,
        },
        {
            value: 6,
            label: "Medium Beard 2",
            src: facialHairMediumBeard2Image,
        },
        {
            value: 7,
            label: "Long Goatee",
            src: facialHairLongGoateeImage,
        },
        {
            value: 8,
            label: "Long Stache",
            src: facialHairLongMoustacheImage,
        },
        {
            value: 9,
            label: "Long Beard 1",
            src: facialHairLongBeardImage,
        },
        {
            value: 10,
            label: "Long Beard 2",
            frontSrc: facialHairLongMoustacheImage,
            src: facialHairLongGoateeImage,
        },
    ],
    head: [
        {
            value: 0,
            label: "Horns 1",
            src: headHorns1Image,
        },
        {
            value: 1,
            label: "Horns 2",
            src: headHorns2Image,
        },
        {
            value: 2,
            label: "Horns 3",
            src: headHorns3Image,
        },
        {
            value: 3,
            label: "Antlers",
            src: headAntlersImage,
        },
        {
            value: 4,
            label: "Harvester Veil",
            src: headHarvesterVeilImage,
            backSrc: headHarvesterVeilUnderImage,
        },
        {
            value: 5,
            label: "Psychopomp",
            src: headPsychopompImage,
        },
        {
            value: 6,
            label: "Infernal",
            src: headInfernalImage,
        },
        {
            value: 7,
            label: "Painting Guardian",
            src: headPaintingGuardianImage,
        },
        {
            value: 8,
            label: "Komuso",
            src: headKomusoImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "Roningasa",
            src: headRoningasaImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "Sandokasa",
            src: headSandokasaImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "Court Magister Hood",
            src: headCourtMagisterHoodImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 12,
            label: "Weaver Hood",
            src: headWeaverHoodImage,
            backSrc: headWeaverHoodUnderImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 13,
            label: "Solstice Hood",
            src: headSolsticeHoodImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chest: [
        {
            value: 0,
            label: "Harvester",
            src: chestHarvesterImage,
        },
        {
            value: 1,
            label: "Harvester - Xixo",
            src: chestXixoHarvesterImage,
        },
        {
            value: 2,
            label: "Psychopomp",
            src: chestPsychopompImage,
        },
        {
            value: 3,
            label: "Solstice",
            src: chestSolsticeImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    legs: [],
    leftArm: [],
    rightArm: [],
    leftWeapon: [
        {
            value: 0,
            label: "Scythe Upright",
            src: leftHandScytheUpImage,
        },
        {
            value: 1,
            label: "Scythe Down",
            src: leftHandScytheDownImage,
        },
        {
            value: 2,
            label: "Quill",
            src: leftHandQuillImage,
        },
    ],
    rightWeapon: [
        {
            value: 0,
            label: "Petals",
            foremostSrc: rightHandPetalsImage,
        },
        {
            value: 1,
            label: "Vines",
            foremostSrc: rightHandVinesImage,
        },
        {
            value: 2,
            label: "Book",
            foremostSrc: rightHandBookImage,
        },
        {
            value: 3,
            label: "Hourglass",
            foremostSrc: rightHandHourglassImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "Scythe Ribbon - Front",
            frontSrc: accessoryScytheRibbonImage,
        },
        {
            value: 1,
            label: "Scythe Ribbon - Behind",
            backSrc: accessoryScytheRibbonImage,
        },
    ],
    partList: [
        {
            label: "All",
            value: "ALL",
        },
        {
            label: "Skin Tone",
            value: "BODY",
        },
        {
            label: "Armor",
            value: "ARMOR",
        },
        {
            label: "Weapons",
            value: "WEAPONS",
        },
        {
            label: "All Hair",
            value: "ALLHAIR",
        },
        {
            label: "Body",
            value: "skin",
        },
        {
            label: "Face",
            value: "face",
        },
        {
            label: "Hair",
            value: "hair",
        },
        {
            label: "Facial Hair",
            value: "facialHair",
        },
        {
            label: "Head",
            value: "head",
        },
        {
            label: "Chest",
            value: "chest",
        },
        {
            label: "Left Hand",
            value: "leftWeapon",
        },
        {
            label: "Right Hand",
            value: "rightWeapon",
        },
        {
            label: "Accessory 1",
            value: "accessory1",
        },
        {
            label: "Accessory 2",
            value: "accessory2",
        },
        {
            label: "Accessory 3",
            value: "accessory3",
        },
        {
            label: "Accessory 4",
            value: "accessory4",
        },
    ],
    labels: {
        "skin": "Body",
        "face": "Face",
        "eyes": "Empty",
        "hair": "Hair",
        "facialHair": "Facial Hair",
        "head": "Head",
        "chest": "Chest",
        "legs": "Empty",
        "leftArm": "Empty",
        "rightArm": "Empty",
        "leftWeapon": "Left Hand",
        "rightWeapon": "Right Hand",
        "accessory1": "Accessory 1",
        "accessory2": "Accessory 2",
        "accessory3": "Accessory 3",
        "accessory4": "Accessory 4",
    },
    templateList: [
        {
            label: "Buff",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                },
            )),
        },
        {
            label: "Harvester",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "skinValue": "RANDOM",
                    "faceValue": "RANDOM",
                    "hairValue": "RANDOM",
                    "chestValue": "0",
                },
            )),
        },
        {
            label: "Psychopomp",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "chestValue": "2",
                    "headValue": "5",
                    "leftWeaponValue": "0",
                    "rightWeaponValue": "3",
                },
            )),
        },
        {
            label: "Solstice",
                isPremium: true,
                isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "chestValue": "3",
                    "headValue": "13",
                    "leftWeaponValue": "1",
                    "rightWeaponValue": "1",
                },
            )),
        },
    ],
};
