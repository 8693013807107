/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext } from "react";

import { UserAuthContext } from "./../../User/UserAuthContextWrapper";
import { AnalyticsContext } from "./../../Analytics/AnalyticsContextWrapper";
import { UnitContext } from "./../UnitContextWrapper";

import UnitCenterButton, { UnitDualCenterButton } from "./../styles/UnitCenterButton";
import { safeName } from "./../Print/PrintAnalytics";
import { drawImages } from "./ExportImages";
import ColorList, { OpacityList } from "./../../common/ColorList";
import { premiumTiersList } from "./PremiumPopup";
import { SPRITE_KEYS } from "../../common/styles/SpriteImage";

function ExportFantasyButton({unit, setShowPremiumPopupFunc, isFree = false, isZIP = false}) {
    const { user } = useContext(UserAuthContext);
    const ReactGA = useContext(AnalyticsContext);
    const { unitBackgroundColor, unitBackgroundOpacity } = useContext(UnitContext);

    const isPremiumUser = user && user.isPremiumUser;
    const isLegendaryUser = user && user.isLegendaryUser;

    const anchorRef = createRef();
    const canvasRef = createRef();

    let imageSize = 150;

    if (unit.isLarge) {
        imageSize = 200;
    }

    if (unit.isExtraLarge) {
        imageSize = 250;
    }

    async function printFantasyUnit(unit) {
        if (isZIP) {
            anchorRef.current.href = unit.downloadSrc;

            ReactGA.event({
                category: "Print",
                action: "Export",
                label: `${isFree ? "" : "Fantasy-"}${unit.filePrefix}-${safeName(unit.unitNumber)}-${safeName(unit.variant)}`,
            });

            anchorRef.current.click();

            return;
        }

        if (unit.isGIF) {
            anchorRef.current.href = unit.src;

            ReactGA.event({
                category: "Print",
                action: "Export",
                label: `${isFree ? "" : "Fantasy-"}${unit.filePrefix}-${safeName(unit.unitNumber)}-${unit.variant}`,
            });

            anchorRef.current.click();

            return;
        }

        let imageLayersArray = [];

        const canvas = canvasRef.current;
        var ctx = canvas.getContext('2d');

        let compositeImage;

        imageLayersArray.push({
            src: unit.src,
            filter: "none",
        });

        ctx.fillStyle = `${ColorList[unitBackgroundColor].hex}${OpacityList[unitBackgroundOpacity].opacity}`;
        ctx.fillRect(0, 0, unit.width ?? imageSize, unit.height ?? imageSize);

        await drawImages(ctx, imageLayersArray);

        compositeImage = await canvas.toDataURL("image/png");

        anchorRef.current.download = `${unit.filePrefix}-${safeName(unit.unitNumber)}-${unit.variant}.png`;
        anchorRef.current.href = compositeImage;
        navigator.clipboard.writeText(compositeImage);

        ReactGA.event({
            category: "Print",
            action: "Export",
            label: `${isFree ? "" : "Fantasy-"}${unit.filePrefix}-${safeName(unit.unitNumber)}-${unit.variant}`,
        });

        anchorRef.current.click();

        ctx.clearRect(0, 0, unit.width ?? imageSize, unit.height ?? imageSize);
    }

    const args = {
        alt: "Export Unit Button",
        title: "Download Unit As PNG",
        className: SPRITE_KEYS.downloadButton,
        onClick: () => {
            if (isFree) {
                return printFantasyUnit(unit);
            }

            if (unit.legendary) {
                if (!isLegendaryUser) {
                    return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                }

                return printFantasyUnit(unit);
            } else if (unit.previewSrc) {
                if (!isPremiumUser) {
                    return setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE);
                }

                return printFantasyUnit(unit);
            } else {
                if (!isPremiumUser) {
                    return setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE);
                }

                return printFantasyUnit(unit);
            }
        },
        displayControl: true,
    };

    return (
        <>
            {isZIP ?
                <UnitCenterButton {...args} /> :
                <UnitDualCenterButton {...args} />
            }
            <canvas ref={canvasRef} height={unit.height ?? imageSize} width={unit.width ?? imageSize} crossOrigin="anonymous" style={{display: "none"}} />
            <a ref={anchorRef} target="_blank" download style={{display: "none"}} />
        </>
    );
}

export default ExportFantasyButton;
