import { nameRegex } from "../Print/PrintAnalytics";

// Return layer options sorted by zIndex descending order
export function createLayerOptions({unit, brandList, layerOrder}) {
    if (typeof unit.viewCoreImage === "number") {
        return [
            {
                key: "core-image",
                tintValue: "none",
                isRegular: brandList[unit.brandValue][unit.frameValue].isRegular,
                isLarge: brandList[unit.brandValue][unit.frameValue].isLarge,
                isExtraLarge: brandList[unit.brandValue][unit.frameValue].isExtraLarge,
                isMegadeus: brandList[unit.brandValue][unit.frameValue].isMegadeus,
                isMegadeusPlus: brandList[unit.brandValue][unit.frameValue].isMegadeusPlus,
                isMegadeusPlusPlus: brandList[unit.brandValue][unit.frameValue].isMegadeusPlusPlus,
                isMegadeusOmega: brandList[unit.brandValue][unit.frameValue].isMegadeusOmega,
                src: brandList[unit.brandValue][unit.frameValue].coreImg[unit.viewCoreImage].src,
                alt: `core-version-${brandList[unit.brandValue][unit.frameValue].label}`,
                zIndex: "150",
            },
        ];
    }

    let layerOptions = [];

    layerOrder.forEach((option) => {
        option.layers.forEach((layer) => {
            if (
                unit[option.name] !== "NONE" &&
                option.stanceValue ?
                    brandList[unit.brandValue][unit.frameValue][option.slot]?.[unit[option.stanceValue]]?.[unit[option.name]]?.[layer.name]
                    : brandList[unit.brandValue][unit.frameValue][option.slot]?.[unit[option.name]]?.[layer.name]
            ) {
                let src;

                if (layer.rules?.length) {
                    let rulePassed = false;

                    layer.rules.forEach((rule) => {
                        if (rule.stanceValue) {
                            if (brandList[unit.brandValue][unit.frameValue][rule.slot][unit[rule.stanceValue]][unit[rule.value]]?.[rule.check]) {
                                rulePassed = rule.result;
                            }
                        } else if (rule.slot) {
                            if (brandList[unit.brandValue][unit.frameValue][rule.slot][unit[rule.value]]?.[rule.check]) {
                                rulePassed = rule.result;
                            }
                        } else if (unit[rule.value] === rule.check) {
                            rulePassed = rule.result;
                        }
                    });

                    switch(rulePassed) {
                        case "remove":
                            break;

                        case "replace-with-altSrc":
                            src = option.stanceValue ?
                                brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.stanceValue]][unit[option.name]].altSrc
                                : brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.name]]?.altSrc;
                            break;

                        default:
                            src = option.stanceValue ?
                                brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.stanceValue]][unit[option.name]][layer.name]
                                : brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.name]]?.[layer.name];
                            break;
                    }
                } else {
                    src = option.stanceValue ?
                        brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.stanceValue]][unit[option.name]][layer.name]
                        : brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.name]]?.[layer.name];
                }

                if (src) {
                    const altValue = option.stanceValue ?
                        `${option.slot}-${unit[option.stanceValue]}-${layer.name}-${unit[option.name]}-${option.name}`
                        : `${option.slot}-${layer.name}-${unit[option.name]}-${option.name}`;

                    layerOptions.push({
                        key: altValue,
                        tintValue: unit[option.tintValue],
                        isRegular: brandList[unit.brandValue][unit.frameValue].isRegular,
                        isLarge: brandList[unit.brandValue][unit.frameValue].isLarge,
                        isMegadeus: brandList[unit.brandValue][unit.frameValue].isMegadeus,
                        isMegadeusPlus: brandList[unit.brandValue][unit.frameValue].isMegadeusPlus,
                        isMegadeusPlusPlus: brandList[unit.brandValue][unit.frameValue].isMegadeusPlusPlus,
                        isMegadeusOmega: brandList[unit.brandValue][unit.frameValue].isMegadeusOmega,
                        src: src,
                        alt: altValue,
                        zIndex: layer.zIndex,
                    });
                }
            }
        });
    });

    return layerOptions.sort((a, b) => b.zIndex - a.zIndex);
}

export function createExportLayerOptions({unit, brandList, layerOrder}) {
    let layerOptions = [];

    layerOrder.forEach((option) => {
        const folderName = `${brandList[unit.brandValue][unit.frameValue].labels[option.slot] === undefined ? brandList[unit.brandValue][unit.frameValue].labels[option.slot + "1"] : brandList[unit.brandValue][unit.frameValue].labels[option.slot]}/`;

        option.layers.forEach((layer) => {
            if (
                unit[option.name] !== "NONE" &&
                option.stanceValue ?
                    brandList[unit.brandValue][unit.frameValue][option.slot]?.[unit[option.stanceValue]]?.[unit[option.name]]?.[layer.name]
                    : brandList[unit.brandValue][unit.frameValue][option.slot]?.[unit[option.name]]?.[layer.name]
            ) {
                let src;

                if (layer.rules?.length) {
                    let rulePassed = false;

                    layer.rules.forEach((rule) => {
                        if (rule.stanceValue) {
                            if (brandList[unit.brandValue][unit.frameValue][rule.slot][unit[rule.stanceValue]][unit[rule.value]]?.[rule.check]) {
                                rulePassed = rule.result;
                            }
                        } else if (rule.slot) {
                            if (brandList[unit.brandValue][unit.frameValue][rule.slot][unit[rule.value]]?.[rule.check]) {
                                rulePassed = rule.result;
                            }
                        } else if (unit[rule.value] === rule.check) {
                            rulePassed = rule.result;
                        }
                    });

                    switch(rulePassed) {
                        case "remove":
                            break;

                        case "replace-with-altSrc":
                            src = option.stanceValue ?
                                brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.stanceValue]][unit[option.name]].altSrc
                                : brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.name]]?.altSrc;
                            break;

                        default:
                            src = option.stanceValue ?
                                brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.stanceValue]][unit[option.name]][layer.name]
                                : brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.name]]?.[layer.name];
                            break;
                    }
                } else {
                    src = option.stanceValue ?
                        brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.stanceValue]][unit[option.name]][layer.name]
                        : brandList[unit.brandValue][unit.frameValue][option.slot][unit[option.name]]?.[layer.name];
                }

                if (src) {
                    const name = option.stanceValue ?
                        `${folderName}${option.slot}-${unit[option.stanceValue]}${layer.name.replaceAll("src", "").replaceAll("Src", "") ? `-${layer.name.replaceAll("src", "").replaceAll("Src", "")}` : ""}.png`
                        : `${folderName}${option.slot}${option.number ? `-${option.number}` : ""}${layer.name.replaceAll("src", "").replaceAll("Src", "") ? `-${layer.name.replaceAll("src", "").replaceAll("Src", "")}` : ""}.png`;

                    layerOptions.push({
                        filter: unit[option.tintValue],
                        src,
                        name,
                        zIndex: layer.zIndex,
                    });
                }
            }
        });
    });

    return layerOptions.sort((a, b) => a.zIndex - b.zIndex);
}

// NPC Only Function
export function createExportLayerOptionsWithDefaults({unit, unitDefaults, layerOrder}) {
    let layerOptions = [];

    layerOrder.forEach((option) => {
        option.layers.forEach((layer) => {
            if (
                unitDefaults?.[option?.name] !== "NONE" &&
                !!unit?.[option?.slot]?.[unitDefaults?.[option?.name]]?.[layer?.name]
            ) {
                let src;

                if (layer.rules?.length) {
                    let rulePassed = false;

                    layer.rules.forEach((rule) => {
                        if (rule.stanceValue) {
                            if (unit[rule.slot][unit[rule.stanceValue]]?.[rule.check]) {
                                rulePassed = rule.result;
                            }
                        } else if (rule.slot) {
                            if (unit[rule.slot][unit[rule.value]]?.[rule.check]) {
                                rulePassed = rule.result;
                            }
                        } else if (unit[rule.value] === rule.check) {
                            rulePassed = rule.result;
                        }
                    });

                    switch(rulePassed) {
                        case "remove":
                            break;

                        case "replace-with-altSrc":
                            src = unit[option.slot][unitDefaults[option.name]].altSrc;
                            break;

                        default:
                            src = unit[option.slot][unitDefaults[option.name]][layer.name];
                            break;
                    }
                } else {
                    src = unit?.[option?.slot]?.[unitDefaults?.[option?.name]]?.[layer?.name];
                }

                if (src) {
                    const folderName = `${unit.labels[option.slot] === undefined ? option.slot.toUpperCase() : unit.labels[option.slot]}/`

                    layerOptions.push({
                        filter: unitDefaults[option.tintValue],
                        src: src,
                        name: folderName + `${option.slot}${option.number ? `-${option.number}` : ""}${layer.name.replaceAll("src", "").replaceAll("Src", "") ? `-${layer.name.replaceAll("src", "").replaceAll("Src", "")}` : ""}.png`,
                        zIndex: layer.zIndex,
                    });
                }
            }
        });
    });

    return layerOptions.sort((a, b) => a.zIndex - b.zIndex);
}

export function createExportLayerOptionsForAllParts({unit, brandList, optionList, layerList, layerOrder}) {
    let imageLayersArray = [];

    optionList.forEach((option) => {
        const folderName = `${brandList[unit.brandValue][unit.frameValue].labels[option] === undefined ? brandList[unit.brandValue][unit.frameValue].labels[option + "1"] : brandList[unit.brandValue][unit.frameValue].labels[option]}/`;

        if (layerOrder.find((layer) => layer.slot === option).stanceValue) {
            if (Object.entries(brandList[unit.brandValue][unit.frameValue][option]).length) {
                Object.entries(brandList[unit.brandValue][unit.frameValue][option]).forEach((stance) => {
                    Object.entries(stance[1]).forEach((part) => {
                        layerList.forEach((layer) => {
                            if (part[1][layer]) {
                                imageLayersArray.push({
                                    src: part[1][layer],
                                    filter: unit[layerOrder.find((layer) => layer.slot === option).tintValue],
                                    name: folderName + `${stance[0]}-${part[1].label}-${layer}.png`.replaceAll(nameRegex, "_"),
                                });
                            }
                        });
                    });
                });
            }
        } else {
            brandList[unit.brandValue][unit.frameValue][option].forEach((part) => {
                layerList.forEach((layer) => {
                    if (part[layer]) {
                        imageLayersArray.push({
                            src: part[layer],
                            filter: unit[layerOrder.find((layer) => layer.slot === option).tintValue],
                            name: folderName + `${option}-${part.label}-${layer}.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            });
        }
    });

    return imageLayersArray;
}

export function createExportLayerOptionsForAllPartsForColor({unit, color, brandList, optionList, layerList, layerOrder}) {
    let imageLayersArray = [];

    optionList.forEach((option) => {
        const folderName = `${brandList[unit.brandValue][unit.frameValue].labels[option] === undefined ? brandList[unit.brandValue][unit.frameValue].labels[option + "1"] : brandList[unit.brandValue][unit.frameValue].labels[option]}/`;

        if (layerOrder.find((layer) => layer.slot === option).stanceValue) {
            if (Object.entries(brandList[unit.brandValue][unit.frameValue][option]).length) {
                Object.entries(brandList[unit.brandValue][unit.frameValue][option]).forEach((stance) => {
                    Object.entries(stance[1]).forEach((part) => {
                        layerList.forEach((layer) => {
                            if (part[1][layer]) {
                                imageLayersArray.push({
                                    src: part[1][layer],
                                    filter: color,
                                    name: folderName + `${option}-${stance[0]}-${part[1].label}-${layer}.png`.replaceAll(nameRegex, "_"),
                                });
                            }
                        });
                    });
                });
            }
        } else {
            brandList[unit.brandValue][unit.frameValue][option].forEach((part) => {
                layerList.forEach((layer) => {
                    if (part[layer]) {
                        imageLayersArray.push({
                            src: part[layer],
                            filter: color,
                            name: folderName + `${option}-${part.label}-${layer}.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            });
        }
    });

    return imageLayersArray;
}
