import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyStance1Image = `${BaseURL}/Mechs/Custom/Asura/Chassis - Stance 1.png`;
const bodyStance1StiltsImage = `${BaseURL}/Mechs/Custom/Asura/Chassis - Stance 1 - Stilts.png`;
const bodyStance2Image = `${BaseURL}/Mechs/Custom/Asura/Chassis - Stance 2.png`;
const bodyStance2StiltsImage = `${BaseURL}/Mechs/Custom/Asura/Chassis - Stance 2 - Stilts.png`;
const bodyFemStance1StiltsImage = `${BaseURL}/Mechs/Custom/Asura/Chassis F - Stance 1 - Stilts.png`;
const bodyFemStance1Image = `${BaseURL}/Mechs/Custom/Asura/Chassis F - Stance 1.png`;
const bodyFemStance2StiltsImage = `${BaseURL}/Mechs/Custom/Asura/Chassis F - Stance 2 - Stilts.png`;
const bodyFemStance2Image = `${BaseURL}/Mechs/Custom/Asura/Chassis F - Stance 2.png`;

// HEADS
const headAsuraImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Asura.png`;
const headAsuraVisorImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Asura Visor.png`;
const headAngularImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Angular.png`;
const headBlackSpotImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Black Spot.png`;
const headBriarImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Briar.png`;
const headCalibanImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Caliban.png`;
const headDungamImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Dungam.png`;
const headFactoryStandardImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Factory Standard.png`;
const headFrontlineImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Frontline.png`;
const headJudicatorImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Judicator.png`;
const headLudonautImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Ludonaut.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Malice.png`;
const headMecha1Image = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Mecha 1.png`;
const headMecha2Image = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Mecha 2.png`;
const headMecha3Image = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Mecha 3.png`;
const headMecha4Image = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Mecha 4.png`;
const headMecha5Image = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Mecha 5.png`;
const headMecha6Image = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Mecha 6.png`;
const headMecha7Image = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Mecha 7.png`;
const headMecha8Image = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Nelson.png`;
const headOgreImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Ogre.png`;
const headPaladinImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Paladin.png`;
const headPredatorImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Predator.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Rain Transformed Hair.png`;
const headScarabImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Scarab.png`;
const headScopedogImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Scopedog.png`;
const headSkullOrbImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Skull Orb.png`;
const headStalwartImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Stalwart.png`;
const headStampedeImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Stampede.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Custom/Asura/heads/Head - Visionary.png`;

// VEILS
const veilDemonImage = `${BaseURL}/Mechs/Custom/Asura/veils/Veil - Demon.png`;
const veilDemonNoHoodImage = `${BaseURL}/Mechs/Custom/Asura/veils/Veil - Demon no hood.png`;
const veilDemonUnderImage = `${BaseURL}/Mechs/Custom/Asura/veils/Veil - Demon Under.png`;
const veilVampireImage = `${BaseURL}/Mechs/Custom/Asura/veils/Veil - Vampire.png`;
const veilVampireStance2Image = `${BaseURL}/Mechs/Custom/Asura/veils/Veil - Vampire Stance 2.png`;
const veilVampireUnderImage = `${BaseURL}/Mechs/Custom/Asura/veils/Veil - Vampire Under.png`;
const veilWerewolfImage = `${BaseURL}/Mechs/Custom/Asura/veils/Veil - Werewolf.png`;
const veilWerewolfUnderImage = `${BaseURL}/Mechs/Custom/Asura/veils/Veil - Werewolf - Under.png`;

// WEAPONS
const weaponSwordsStance1Image = `${BaseURL}/Mechs/Custom/Asura/weapons/Weapons - Swords - Stance 1.png`;
const weaponSwordsStance2Image = `${BaseURL}/Mechs/Custom/Asura/weapons/Weapons - Swords - Stance 2.png`;
const weaponSwordsUnderImage = `${BaseURL}/Mechs/Custom/Asura/weapons/Weapons - Swords Under.png`;
const weaponAutoshotgunsStance1Image = `${BaseURL}/Mechs/Custom/Asura/weapons/Weapons - Autoshotguns - Stance 1.png`;
const weaponGunsStance2Image = `${BaseURL}/Mechs/Custom/Asura/weapons/Weapons - Guns - Stance 2.png`;
const weaponGunsStance2UnderImage = `${BaseURL}/Mechs/Custom/Asura/weapons/Weapons - Guns - Stance 2 Under.png`;

// GLOWS
const glowDemonImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Demon.png`;
const glowDemonNoHoodImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Demon no hood.png`;
const glowVampireImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Vampire.png`;
const glowVampireStance2Image = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Vampire Stance 2.png`;
const glowWerewolfImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Werewolf.png`;
const glowGunsImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Guns.png`;
const glowSwordsStance1Image = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Swords Stance 1.png`;
const glowSwordsStance2Image = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Swords Stance 2.png`;
const glowAsuraHeadImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Asura Head.png`;
const glowAsuraHeadAmgryImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Visor - Amgry.png`;
const glowAsuraHeadCircleImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Visor - Circle.png`;
const glowAsuraHeadColonDImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Visor - Colon D.png`;
const glowAsuraHeadGreater3Image = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Visor - Greater 3.png`;
const glowAsuraHeadMortalAreaImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Visor - Mortal Area.png`;
const glowAsuraHeadSkullImage = `${BaseURL}/Mechs/Custom/Asura/glows/Glow - Visor - Skull.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Asura/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Asura/preview-restricted.png`;

const commissionCredit = {
    prefixText: "Commissioned by ",
    title: "DHDragon",
    url: "https://discord.gg/JUF47G5",
};

const artCredit = {
    prefixText: "Created by ",
    title: "SkaianDestiny",
    url: "https://discord.gg/JUF47G5",
};

const regularShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "88px",
    right: "63px",
};

const stiltsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "72px",
    right: "66px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "stanceValue",
    hasMultipleStances: true,
    defaultValues: `{
        "chassisValue": "1",
        "headValue": "1",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "1",
        "accessory1Value": "1",
        "accessory2Value": "10",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ASURA",
            src: headAsuraImage,
        },
        {
            value: 1,
            label: "ASURA VISOR",
            src: headAsuraVisorImage,
        },
        {
            value: 2,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 3,
            label: "BLACK SPOT",
            src: headBlackSpotImage,
        },
        {
            value: 4,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 6,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 7,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
        },
        {
            value: 8,
            label: "FRONTLINE",
            src: headFrontlineImage,
        },
        {
            value: 9,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 10,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 11,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 12,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 13,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 14,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 15,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 16,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 17,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 18,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 19,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 20,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 21,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 22,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 23,
            label: "OGRE",
            src: headOgreImage,
        },
        {
            value: 24,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 25,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 26,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 27,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 28,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 29,
            label: "SCOPEDOG",
            src: headScopedogImage,
        },
        {
            value: 30,
            label: "SKULL ORB",
            src: headSkullOrbImage,
            originalArtCredit: {
                title: "@ConceptualKory",
                url: "https://twitter.com/ConceptualKory",
            },
        },
        {
            value: 31,
            label: "STALWART",
            src: headStalwartImage,
        },
        {
            value: 32,
            label: "STAMPEDE",
            src: headStampedeImage,
        },
        {
            value: 33,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "RAISED STANCE",
            src: bodyStance1Image,
            shadowDetails: regularShadow,
            originalArtCredit: commissionCredit,
        },
        {
            value: 1,
            label: "RAISED STANCE - STILTS",
            src: bodyStance1StiltsImage,
            shadowDetails: stiltsShadow,
            originalArtCredit: commissionCredit,
        },
        {
            value: 2,
            label: "LOWERED STANCE",
            src: bodyStance2Image,
            shadowDetails: regularShadow,
            originalArtCredit: commissionCredit,
        },
        {
            value: 3,
            label: "LOWERED STANCE - STILTS",
            src: bodyStance2StiltsImage,
            shadowDetails: stiltsShadow,
            originalArtCredit: commissionCredit,
        },
        {
            value: 4,
            label: "FEM - RAISED STANCE",
            src: bodyFemStance1Image,
            shadowDetails: regularShadow,
            originalArtCredit: artCredit,
        },
        {
            value: 5,
            label: "FEM - RAISED STANCE - STILTS",
            src: bodyFemStance1StiltsImage,
            shadowDetails: stiltsShadow,
            originalArtCredit: artCredit,
        },
        {
            value: 6,
            label: "FEM - LOWERED STANCE",
            src: bodyFemStance2Image,
            shadowDetails: regularShadow,
            originalArtCredit: artCredit,
        },
        {
            value: 7,
            label: "FEM - LOWERED STANCE - STILTS",
            src: bodyFemStance2StiltsImage,
            shadowDetails: stiltsShadow,
            originalArtCredit: artCredit,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "SWORDS",
                foremostSrc: weaponSwordsStance1Image,
                backSrc: weaponSwordsUnderImage,
            },
            {
                value: 1,
                label: "GUNS",
                foremostSrc: weaponAutoshotgunsStance1Image,
            },
        ],
        "1": [
            {
                value: 0,
                label: "SWORDS",
                foremostSrc: weaponSwordsStance1Image,
                backSrc: weaponSwordsUnderImage,
            },
            {
                value: 1,
                label: "GUNS",
                foremostSrc: weaponAutoshotgunsStance1Image,
            },
        ],
        "2": [
            {
                value: 0,
                label: "SWORDS",
                foremostSrc: weaponSwordsStance2Image,
                backSrc: weaponSwordsUnderImage,
            },
            {
                value: 1,
                label: "GUNS",
                foremostSrc: weaponGunsStance2Image,
                backSrc: weaponGunsStance2UnderImage,
            },
        ],
        "3": [
            {
                value: 0,
                label: "SWORDS",
                foremostSrc: weaponSwordsStance2Image,
                backSrc: weaponSwordsUnderImage,
            },
            {
                value: 1,
                label: "GUNS",
                foremostSrc: weaponGunsStance2Image,
                backSrc: weaponGunsStance2UnderImage,
            },
        ],
        "4": [
            {
                value: 0,
                label: "SWORDS",
                foremostSrc: weaponSwordsStance1Image,
                backSrc: weaponSwordsUnderImage,
            },
            {
                value: 1,
                label: "GUNS",
                foremostSrc: weaponAutoshotgunsStance1Image,
            },
        ],
        "5": [
            {
                value: 0,
                label: "SWORDS",
                foremostSrc: weaponSwordsStance1Image,
                backSrc: weaponSwordsUnderImage,
            },
            {
                value: 1,
                label: "GUNS",
                foremostSrc: weaponAutoshotgunsStance1Image,
            },
        ],
        "6": [
            {
                value: 0,
                label: "SWORDS",
                foremostSrc: weaponSwordsStance2Image,
                backSrc: weaponSwordsUnderImage,
            },
            {
                value: 1,
                label: "GUNS",
                foremostSrc: weaponGunsStance2Image,
                backSrc: weaponGunsStance2UnderImage,
            },
        ],
        "7": [
            {
                value: 0,
                label: "SWORDS",
                foremostSrc: weaponSwordsStance2Image,
                backSrc: weaponSwordsUnderImage,
            },
            {
                value: 1,
                label: "GUNS",
                foremostSrc: weaponGunsStance2Image,
                backSrc: weaponGunsStance2UnderImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DEMON - RAISED STANCE",
            src: veilDemonNoHoodImage,
            backSrc: veilDemonUnderImage,
        },
        {
            value: 1,
            label: "DEMON - HOOD - RAISED STANCE",
            frontSrc: veilDemonImage,
            backSrc: veilDemonUnderImage,
        },
        {
            value: 2,
            label: "VAMPIRE",
            src: veilVampireImage,
            backSrc: veilVampireUnderImage,
        },
        {
            value: 3,
            label: "VAMPIRE - LOWERED STANCE",
            src: veilVampireStance2Image,
            backSrc: veilVampireUnderImage,
        },
        {
            value: 4,
            label: "WEREWOLF",
            src: veilWerewolfImage,
            backSrc: veilWerewolfUnderImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "DEMON - RAISED STANCE",
            frontSrc: glowDemonNoHoodImage,
        },
        {
            value: 1,
            label: "DEMON - HOOD - RAISED STANCE",
            frontSrc: glowDemonImage,
        },
        {
            value: 2,
            label: "VAMPIRE",
            frontSrc: glowVampireImage,
        },
        {
            value: 3,
            label: "VAMPIRE - LOWERED STANCE",
            frontSrc: glowVampireStance2Image,
        },
        {
            value: 4,
            label: "WEREWOLF",
            frontSrc: glowWerewolfImage,
        },
        {
            value: 5,
            label: "SWORDS - RAISED STANCE",
            foremostSrc: glowSwordsStance1Image,
        },
        {
            value: 6,
            label: "SWORDS - LOWERED STANCE",
            foremostSrc: glowSwordsStance2Image,
        },
        {
            value: 7,
            label: "GUNS - LOWERED STANCE",
            foremostSrc: glowGunsImage,
        },
        {
            value: 8,
            label: "ASURA HEAD",
            frontSrc: glowAsuraHeadImage,
        },
        {
            value: 9,
            label: "ASURA VISOR - AMGRY",
            frontSrc: glowAsuraHeadAmgryImage,
        },
        {
            value: 10,
            label: "ASURA VISOR - CIRCLE",
            frontSrc: glowAsuraHeadCircleImage,
        },
        {
            value: 11,
            label: "ASURA VISOR - COLON D",
            frontSrc: glowAsuraHeadColonDImage,
        },
        {
            value: 12,
            label: "ASURA VISOR - GREATER 3",
            frontSrc: glowAsuraHeadGreater3Image,
        },
        {
            value: 13,
            label: "ASURA VISOR - MORTAL AREA",
            frontSrc: glowAsuraHeadMortalAreaImage,
        },
        {
            value: 14,
            label: "ASURA VISOR - SKULL",
            frontSrc: glowAsuraHeadSkullImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPONS",
            value: "weapon",
        },
        {
            label: "VEIL",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "WEAPONS",
        "secondaryWeapon": "VEIL",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
