import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const unitPath = `${BaseURL}/Heroes/Relicts-Tokens/Husk`;

// BODY
const bodyImage = `${unitPath}/Husk - Gray.png`;

// GLOW
const glowImage = `${unitPath}/Husk - Glow.png`;

const previewImage = `${unitPath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "39px",
    right: "37px",
};

export default {
    previewImg: previewImage,
    isLarge: true,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "0",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "Husk",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: [],
    secondaryWeapon: [
        {
            value: 0,
            label: "Main",
            src: glowImage,
        },
    ],
    camo: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "Body",
            value: "chassis",
        },
        {
            label: "Glow",
            value: "secondaryWeapon",
            isGlow: true,
        },
    ],
    labels: {
        "chassis": "Body",
        "weapon": "Empty",
        "secondaryWeapon": "Glow",
        "camo1": "Empty",
        "camo2": "Empty",
        "camo3": "Empty",
        "camo4": "Empty",
    },
};
