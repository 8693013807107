import { OPTIONS, LAYERS } from "./Layers";

import HeroesRelicts from "../data/Heroes-Relicts";
import HeroesRelictsTokens from "../data/Heroes-Relicts-Tokens";

export const brandList = {
    "Relicts": HeroesRelicts,
    "Relicts-Tokens": HeroesRelictsTokens,
};

export const npcOptionList = [
    OPTIONS.weapon,
    OPTIONS.secondaryWeapon,
    OPTIONS.chassis,
    OPTIONS.camo,
];

export const npcLayerList = [
    LAYERS.foremostSrc,
    LAYERS.frontSrc,
    LAYERS.src,
    LAYERS.backSrc,
];

export const npcLayerOrder = [
    {
        name: "weaponValue",
        slot: "weapon",
        tintValue: "weaponTintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 130,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 110,
            },
            {
                name: LAYERS.src,
                zIndex: 100,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 30,
            },
        ],
    },
    {
        name: "secondaryWeaponValue",
        slot: "secondaryWeapon",
        tintValue: "secondaryWeaponTintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 131,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 111,
            },
            {
                name: LAYERS.src,
                zIndex: 101,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 31,
            },
        ],
    },
    {
        name: "chassisValue",
        slot: "chassis",
        tintValue: "chassisTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 90,
                rules: [
                    {
                        slot: "weapon",
                        value: "weaponValue",
                        check: "removeBodyCover",
                        result: "remove",
                    },
                ],
            },
            {
                name: LAYERS.src,
                zIndex: 60,
            },
        ],
    },
    {
        name: "camo1Value",
        number: 1,
        slot: "camo",
        tintValue: "camo1TintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 138,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 123,
            },
            {
                name: LAYERS.src,
                zIndex: 73,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 23,
            }
        ],
    },
    {
        name: "camo2Value",
        number: 2,
        slot: "camo",
        tintValue: "camo2TintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 137,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 122,
            },
            {
                name: LAYERS.src,
                zIndex: 72,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 22,
            }
        ],
    },
    {
        name: "camo3Value",
        number: 3,
        slot: "camo",
        tintValue: "camo3TintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 136,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 121,
            },
            {
                name: LAYERS.src,
                zIndex: 71,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 21,
            }
        ],
    },
    {
        name: "camo4Value",
        number: 4,
        slot: "camo",
        tintValue: "camo4TintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 135,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 120,
            },
            {
                name: LAYERS.src,
                zIndex: 70,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 20,
            }
        ],
    },
];
