import BaseURL from "./images/Hosting-BaseURL";
import { removeTintValues } from "./common/settings";
import { shadowTypes } from "../styles/UnitShadow";

// BODY
const bodyMasculineImage = `${BaseURL}/Heroes/Sealer/body/Body M.png`;
const bodyFeminineImage = `${BaseURL}/Heroes/Sealer/body/Body F.png`;
// PREMIUM
const bodyReptileTailImage = `${BaseURL}/Heroes/Sealer/body/Tail - Reptile.png`;
const bodyFelineTailImage = `${BaseURL}/Heroes/Sealer/body/Tail - Feline.png`;
const bodyLupineTailImage = `${BaseURL}/Heroes/Sealer/body/Tail - Lupine.png`;

// const bodyElfEarsFrontImage = `${BaseURL}/Heroes/Sealer/body/premium/Helmet - Elf Ears.png`;
const bodyCatEarsFrontImage = `${BaseURL}/Heroes/Sealer/head/Head - Cat Ears.png`;
const bodyCatEarsBackImage = `${BaseURL}/Heroes/Sealer/head/Head - Cat Ears Under.png`;
// FACE
const faceMasculineImage = `${BaseURL}/Heroes/Sealer/face/Face - Masculine.png`;
const faceFeminineImage = `${BaseURL}/Heroes/Sealer/face/Face - Feminine.png`;
const faceElfMasculineImage = `${BaseURL}/Heroes/Sealer/face/Face - Elvish M.png`;
const faceElfFeminineImage = `${BaseURL}/Heroes/Sealer/face/Face - Elvish F.png`;
const faceFrogImage = `${BaseURL}/Heroes/Sealer/face/Face - Frog.png`;
const faceLepusImage = `${BaseURL}/Heroes/Sealer/face/Face - Lepus.png`;
const faceLupineImage = `${BaseURL}/Heroes/Sealer/face/Face - Lupine.png`;
const faceReptileImage = `${BaseURL}/Heroes/Sealer/face/Face - Reptile.png`;
const faceVulpineImage = `${BaseURL}/Heroes/Sealer/face/Face - Vulpine.png`;
const faceOwlImage = `${BaseURL}/Heroes/Sealer/face/Face - Owl.png`;
const faceFennecImage = `${BaseURL}/Heroes/Sealer/face/Face - Fennec.png`;
const faceXixo1Image = `${BaseURL}/Heroes/Sealer/face/Face - Xixo 1.png`;
const faceXixo2Image = `${BaseURL}/Heroes/Sealer/face/Face - Xixo 2.png`;
const faceXixo3Image = `${BaseURL}/Heroes/Sealer/face/Face - Xixo 3.png`;
const faceXixo4Image = `${BaseURL}/Heroes/Sealer/face/Face - Xixo 4.png`;

// EYES
// const eyesGlowRegularImage = `${BaseURL}/Heroes/Sealer/eyes/Accessories - Eye Glow 2.png`;
// const eyesGlowLargeImage = `${BaseURL}/Heroes/Sealer/eyes/Accessories - Eye Glow.png`;
// const eyesFeminineImage = `${BaseURL}/Heroes/Sealer/eyes/Eyes - Feminine.png`;
// PREMIUM
// const eyesLupineImage = `${BaseURL}/Heroes/Sealer/eyes/premium/Eyes - Lupine.png`;
// const eyesReptilekinImage = `${BaseURL}/Heroes/Sealer/eyes/premium/Eyes - Reptilekin.png`;

// HAIR
const hairShortPartedImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Parted.png`;
const hairAfroImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Afro.png`;
const hairTousledImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Short Tousled.png`;
const hairFauxhawkImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Faux Hawk.png`;
const hairFlowingLocksImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Flowing Locks.png`;
const hairGuileImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Guile.png`;
const hairHiroProtagonistImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Hiro Protagonist.png`;
const hairTheMajorImage = `${BaseURL}/Heroes/Sealer/hair/Hair - The Major.png`;
const hairWildImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Wild.png`;
const hairMediumBackImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Back.png`;
const hairBobbedImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Bobbed.png`;
const hairLongWavyBangsImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Long.png`;
const hairLongUnderImage = `${BaseURL}/Heroes/Sealer/hair/Hair Under - Long Hair.png`;
const hairLongBraidUnderImage = `${BaseURL}/Heroes/Sealer/hair/Hair Under - Long Braid.png`;
const hairLongWavyPartedImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Long Parted.png`;
const hairLongPonytailUnderImage = `${BaseURL}/Heroes/Sealer/hair/Hair Under - Ponytail.png`;
const hairTrogg1Image = `${BaseURL}/Heroes/Sealer/hair/Hair - Trogg 1.png`;
const hairTrogg1UnderImage = `${BaseURL}/Heroes/Sealer/hair/Hair - Trogg 1 Under.png`;
const hairTrogg2Image = `${BaseURL}/Heroes/Sealer/hair/Hair - Trogg 2.png`;

// FACIAL HAIR
const facialHairBubImage = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Bub.png`;
const facialHairBubHandlebarImage = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Handlebar Bub.png`;
const facialHairHandlebarMoustacheImage = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Handlebar Moustache.png`;
const facialHairHandlebarMoustacheBeardImage = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Handlebar and Beard.png`;
const facialHairShortBeard1Image = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Short Bear 1.png`;
const facialHairShortBeard2Image = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Short Beard 2.png`;
const facialHairMediumBeard1Image = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Medium Beard.png`;
const facialHairMediumBeard2Image = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Medium Orcish.png`;
const facialHairLongGoateeImage = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Long Goatee.png`;
const facialHairLongSideburnMoustacheImage = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Long Beard Moustache.png`;
const facialHairLongBeardImage = `${BaseURL}/Heroes/Sealer/hair/facial/Facial Hair- Long Beard.png`;

// HEAD
const headHorns1Image = `${BaseURL}/Heroes/Sealer/head/Head - Horns 1.png`;
const headHorns2Image = `${BaseURL}/Heroes/Sealer/head/Head - Horns 2.png`;
const headHorns3Image = `${BaseURL}/Heroes/Sealer/head/Head - Horns 3.png`;
const headKomusoImage = `${BaseURL}/Heroes/Sealer/head/Head - Komuso.png`;
const headSandokasaImage = `${BaseURL}/Heroes/Sealer/head/Head - Sandokasa.png`;
const headRoningasaImage = `${BaseURL}/Heroes/Sealer/head/Head - Roningasa.png`;
const headEboshiImage = `${BaseURL}/Heroes/Sealer/head/Head - Eboshi.png`;
const headKonmuriImage = `${BaseURL}/Heroes/Sealer/head/Head - Konmuri.png`;
const headPaintingGuardianImage = `${BaseURL}/Heroes/Sealer/head/Head - Painting Guardian.png`;

// CHEST
const chestLoinclothImage = `${BaseURL}/Heroes/Sealer/chest/Outfit - Loincloth Only.png`;
const chestRaggedRobesImage = `${BaseURL}/Heroes/Sealer/chest/Outfit - Ragged Robes.png`;
const chestRobes1Image = `${BaseURL}/Heroes/Sealer/chest/Outfit - Robes 1.png`;
const chestRobes2Image = `${BaseURL}/Heroes/Sealer/chest/Outfit - Robes 2.png`;

// ARMS
const armsWrapsImage = `${BaseURL}/Heroes/Sealer/arms/Arms - Wraps.png`;
const armsBeadsImage = `${BaseURL}/Heroes/Sealer/arms/Arms - Beads.png`;
const armsGunShowImage = `${BaseURL}/Heroes/Sealer/arms/Arms - Gun Show.png`;
const armsXixoImage = `${BaseURL}/Heroes/Sealer/arms/Arms - Xixo.png`;
const armsXixoQuadImage = `${BaseURL}/Heroes/Sealer/arms/Arms - Xixo Quad.png`;
const armsFeatheredImage = `${BaseURL}/Heroes/Sealer/arms/Arms - Feathered.png`;

// LEGS
const legsFootWrapsImage = `${BaseURL}/Heroes/Sealer/legs/Feet - Foot Wraps.png`;
const legsGetaSandalsImage = `${BaseURL}/Heroes/Sealer/legs/Feet - Geta Sandals.png`;
const legsSlippersImage = `${BaseURL}/Heroes/Sealer/legs/Feet - Slippers.png`;
const legsXixoImage = `${BaseURL}/Heroes/Sealer/legs/Feet - Xixo.png`;

// BACK
const backGourdImage = `${BaseURL}/Heroes/Sealer/rear/Rear - Gourd.png`;
const backBoundStoneImage = `${BaseURL}/Heroes/Sealer/rear/Rear - Bound Stone.png`;
const backScrollImage = `${BaseURL}/Heroes/Sealer/rear/Rear - Scroll.png`;
const backSealedBoxImage = `${BaseURL}/Heroes/Sealer/rear/Rear - Sealed Box.png`;
const backShrineImage = `${BaseURL}/Heroes/Sealer/rear/Rear - Shrine.png`;

// ACCESSORIES
const accessoryRopesNakedImage = `${BaseURL}/Heroes/Sealer/accessories/Accessories - Ropes - Naked.png`;
const accessoryRopesRaggedRobesImage = `${BaseURL}/Heroes/Sealer/accessories/Accessories - Ropes - Ragged Robes.png`;
const accessoryRopesRobe1Image = `${BaseURL}/Heroes/Sealer/accessories/Accessories - Ropes - Robe 1.png`;
const accessoryRopesRobe2Image = `${BaseURL}/Heroes/Sealer/accessories/Accessories - Ropes - Robe 2.png`;
const accessoryBeadedNecklaceImage = `${BaseURL}/Heroes/Sealer/accessories/Accessories - Beaded Necklace.png`;
const accessoryFloatingTalismansImage = `${BaseURL}/Heroes/Sealer/accessories/Accessories - Floating Talismans.png`;
const accessoryFlowingVestmentsImage = `${BaseURL}/Heroes/Sealer/accessories/Accessories - Flowing Vestments.png`;

// GLOWS
const glowHaloTopImage = `${BaseURL}/Heroes/Sealer/glows/Glow - Halo Over.png`;
const glowHaloImage = `${BaseURL}/Heroes/Sealer/glows/Glow - Halo.png`;
const glowStaffImage = `${BaseURL}/Heroes/Sealer/glows/Glow - Staff.png`;
const glowTalismansImage = `${BaseURL}/Heroes/Sealer/glows/Glow - Talismans.png`;

// WEAPON
const weaponStaffImage = `${BaseURL}/Heroes/Sealer/accessories/Accessories - Staff.png`;

const previewImage = `${BaseURL}/Heroes/Sealer/preview.png`;

const shadow = {
    size: shadowTypes.SMALL,
    bottom: "79px",
    right: "58px",
};

const defaultSelectionValues = {
    "skinValue": "0",
    "faceValue": "10",
    "eyesValue": "NONE",
    "hairValue": "NONE",
    "facialHairValue": "NONE",
    "headValue": "3",
    "chestValue": "1",
    "legsValue": "1",
    "leftArmValue": "0",
    "rightArmValue": "0",
    "leftWeaponValue": "NONE",
    "rightWeaponValue": "NONE",
    "accessory1Value": "4",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const emptySelectionValues = {
    "skinValue": "0",
    "faceValue": "0",
    "eyesValue": "NONE",
    "hairValue": "NONE",
    "facialHairValue": "NONE",
    "headValue": "NONE",
    "chestValue": "NONE",
    "legsValue": "NONE",
    "leftArmValue": "NONE",
    "rightArmValue": "NONE",
    "leftWeaponValue": "NONE",
    "rightWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const defaultValues = Object.assign({}, defaultSelectionValues, removeTintValues);

export default {
    previewImg: previewImage,
    isExtraLarge: true,
    defaultValues: JSON.stringify(defaultValues),
    skin: [
        {
            value: 0,
            label: "Masculine",
            src: bodyMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 1,
            label: "Feminine",
            src: bodyFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 2,
            label: "Reptilekin Masculine",
            src: bodyMasculineImage,
            backSrc: bodyReptileTailImage,
            shadowDetails: shadow,
        },
        {
            value: 3,
            label: "Reptilekin Feminine",
            src: bodyFeminineImage,
            backSrc: bodyReptileTailImage,
            shadowDetails: shadow,
        },
        {
            value: 4,
            label: "Lupine Masculine",
            src: bodyMasculineImage,
            backSrc: bodyLupineTailImage,
            shadowDetails: shadow,
        },
        {
            value: 5,
            label: "Lupine Feminine",
            src: bodyFeminineImage,
            backSrc: bodyLupineTailImage,
            shadowDetails: shadow,
        },
        {
            value: 6,
            label: "Feline Masculine",
            src: bodyMasculineImage,
            backSrc: bodyFelineTailImage,
            shadowDetails: shadow,
        },
        {
            value: 7,
            label: "Feline Feminine",
            src: bodyFeminineImage,
            backSrc: bodyFelineTailImage,
            shadowDetails: shadow,
        },
    ],
    face: [
        {
            value: 0,
            label: "Masculine",
            src: faceMasculineImage,
        },
        {
            value: 1,
            label: "Feminine",
            src: faceFeminineImage,
        },
        {
            value: 2,
            label: "Elven Masculine",
            src: faceElfMasculineImage,
        },
        {
            value: 3,
            label: "Elven Feminine",
            src: faceElfFeminineImage,
        },
        {
            value: 4,
            label: "Reptilekin",
            src: faceReptileImage,
        },
        {
            value: 5,
            label: "Lupine",
            src: faceLupineImage,
        },
        {
            value: 6,
            label: "Vulpine",
            src: faceVulpineImage,
        },
        {
            value: 7,
            label: "Lepus",
            src: faceLepusImage,
        },
        {
            value: 8,
            label: "Fennec",
            src: faceFennecImage,
        },
        {
            value: 9,
            label: "Owl",
            src: faceOwlImage,
        },
        {
            value: 10,
            label: "Frog",
            src: faceFrogImage,
        },
        {
            value: 11,
            label: "Xixo 1",
            src: faceXixo1Image,
        },
        {
            value: 12,
            label: "Xixo 2",
            src: faceXixo2Image,
        },
        {
            value: 13,
            label: "Xixo 3",
            src: faceXixo3Image,
        },
        {
            value: 14,
            label: "Xixo 4",
            src: faceXixo4Image,
        },
        {
            value: 15,
            label: "Cat Ears Masculine",
            src: faceMasculineImage,
            frontSrc: bodyCatEarsFrontImage,
            backSrc: bodyCatEarsBackImage,
        },
        {
            value: 16,
            label: "Cat Ears Feminine",
            src: faceFeminineImage,
            frontSrc: bodyCatEarsFrontImage,
            backSrc: bodyCatEarsBackImage,
        },
    ],
    eyes: [
        // {
        //     value: 0,
        //     label: "Glow 1",
        //     src: eyesGlowRegularImage,
        // },
        // {
        //     value: 1,
        //     label: "Glow 2",
        //     src: eyesGlowLargeImage,
        // },
        // {
        //     value: 2,
        //     label: "Lupine",
        //     src: eyesLupineImage,
        //     isPremium: true,
        // },
        // {
        //     value: 3,
        //     label: "Reptilekin",
        //     src: eyesReptilekinImage,
        //     isPremium: true,
        // },
    ],
    hair: [
        {
            value: 0,
            label: "Short Parted",
            src: hairShortPartedImage,
        },
        {
            value: 1,
            label: "Medium Back",
            src: hairMediumBackImage,
        },
        {
            value: 2,
            label: "Fauxhawk",
            src: hairFauxhawkImage,
        },
        {
            value: 3,
            label: "Wild",
            src: hairWildImage,
        },
        {
            value: 4,
            label: "Afro",
            src: hairAfroImage,
        },
        {
            value: 5,
            label: "Flowing Locks",
            src: hairFlowingLocksImage,
        },
        {
            value: 6,
            label: "Long Wavy Braid",
            src: hairLongWavyBangsImage,
            backSrc: hairLongBraidUnderImage,
        },
        {
            value: 7,
            label: "Long Wavy Bangs",
            src: hairLongWavyBangsImage,
            backSrc: hairLongUnderImage,
        },
        {
            value: 8,
            label: "Long Wavy Parted",
            src: hairLongWavyPartedImage,
        },
        {
            value: 9,
            label: "Tousled",
            src: hairTousledImage,
        },
        {
            value: 10,
            label: "Guile",
            src: hairGuileImage,
        },
        {
            value: 11,
            label: "Bobbed",
            src: hairBobbedImage,
        },
        {
            value: 12,
            label: "The Major",
            src: hairTheMajorImage,
        },
        {
            value: 13,
            label: "The Major + Ponytail",
            src: hairTheMajorImage,
            backSrc: hairLongPonytailUnderImage,
        },
        {
            value: 14,
            label: "Hiro Protagonist",
            src: hairHiroProtagonistImage,
        },
        {
            value: 15,
            label: "Bald Braid",
            backSrc: hairLongBraidUnderImage,
        },
        {
            value: 16,
            label: "Trogg 1",
            src: hairTrogg1Image,
            backSrc: hairTrogg1UnderImage,
        },
        {
            value: 17,
            label: "Trogg 2",
            src: hairTrogg2Image,
        },
    ],
    facialHair: [
        {
            value: 0,
            label: "Bub",
            src: facialHairBubImage,
        },
        {
            value: 1,
            label: "Handlebar Moustache",
            src: facialHairHandlebarMoustacheImage,
        },
        {
            value: 2,
            label: "Handlebar + Bub",
            src: facialHairBubHandlebarImage,
        },
        {
            value: 3,
            label: "Handlebar + Beard",
            src: facialHairHandlebarMoustacheBeardImage,
        },
        {
            value: 4,
            label: "Short Beard 1",
            src: facialHairShortBeard1Image,
        },
        {
            value: 5,
            label: "Short Beard 2",
            src: facialHairShortBeard2Image,
        },
        {
            value: 6,
            label: "Medium Beard 1",
            src: facialHairMediumBeard1Image,
        },
        {
            value: 7,
            label: "Medium Beard 2",
            src: facialHairMediumBeard2Image,
        },
        {
            value: 8,
            label: "Long Goatee",
            src: facialHairLongGoateeImage,
        },
        {
            value: 9,
            label: "Long Sideburn Moustache",
            src: facialHairLongSideburnMoustacheImage,
        },
        {
            value: 10,
            label: "Long Beard",
            src: facialHairLongBeardImage,
        },
    ],
    head: [
        {
            value: 0,
            label: "Horns 1",
            src: headHorns1Image,
        },
        {
            value: 1,
            label: "Horns 2",
            src: headHorns2Image,
        },
        {
            value: 2,
            label: "Horns 3",
            src: headHorns3Image,
        },
        {
            value: 3,
            label: "Eboshi",
            src: headEboshiImage,
        },
        {
            value: 4,
            label: "Komuso",
            src: headKomusoImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "Sandokasa",
            src: headSandokasaImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "Roningasa",
            src: headRoningasaImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "Konmuri",
            src: headKonmuriImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "Painting Guardian",
            src: headPaintingGuardianImage,
            isPremium: true,
        },
    ],
    chest: [
        {
            value: 0,
            label: "Loincloth",
            src: chestLoinclothImage,
        },
        {
            value: 1,
            label: "Robes 1",
            src: chestRobes1Image,
        },
        {
            value: 2,
            label: "Robes 2",
            src: chestRobes2Image,
        },
        {
            value: 3,
            label: "Ragged Robes",
            src: chestRaggedRobesImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    legs: [
        {
            value: 0,
            label: "Foot Wraps",
            src: legsFootWrapsImage,
        },
        {
            value: 1,
            label: "Geta Sandals",
            src: legsGetaSandalsImage,
        },
        {
            value: 2,
            label: "Slippers",
            src: legsSlippersImage,
        },
        {
            value: 3,
            label: "Xixo",
            src: legsXixoImage,
        },
    ],
    leftArm: [
        {
            value: 0,
            label: "Wraps",
            src: armsWrapsImage,
        },
        {
            value: 1,
            label: "Beads",
            src: armsBeadsImage,
        },
        {
            value: 2,
            label: "Wraps + Beads",
            frontSrc: armsBeadsImage,
            src: armsWrapsImage,
        },
        {
            value: 3,
            label: "Gun Show",
            src: armsGunShowImage,
        },
        {
            value: 4,
            label: "Xixo",
            src: armsXixoImage,
        },
        {
            value: 5,
            label: "Xixo Quad",
            frontSrc: armsXixoQuadImage,
        },
        {
            value: 6,
            label: "Feathered",
            frontSrc: armsFeatheredImage,
        },
    ],
    rightArm: [
        {
            value: 0,
            label: "Shrine",
            backSrc: backShrineImage,
        },
        {
            value: 1,
            label: "Gourd",
            backSrc: backGourdImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "Bound Stone",
            backSrc: backBoundStoneImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "Scroll",
            backSrc: backScrollImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "Sealed Box",
            backSrc: backSealedBoxImage,
            isPremium: true,
        },
    ],
    leftWeapon: [
        {
            value: 0,
            label: "Staff",
            src: weaponStaffImage,
        },
    ],
    rightWeapon: [],
    accessory: [
        {
            value: 0,
            label: "Beaded Necklace",
            src: accessoryBeadedNecklaceImage,
        },
        {
            value: 1,
            label: "Floating Talismans",
            src: accessoryFloatingTalismansImage,
        },
        {
            value: 2,
            label: "Flowing Vestments",
            src: accessoryFlowingVestmentsImage,
        },
        {
            value: 3,
            label: "Ropes - Naked",
            src: accessoryRopesNakedImage,
        },
        {
            value: 4,
            label: "Ropes - Robes 1",
            src: accessoryRopesRobe1Image,
        },
        {
            value: 5,
            label: "Ropes - Robes 2",
            src: accessoryRopesRobe2Image,
        },
        {
            value: 6,
            label: "Ropes - Ragged Robes",
            src: accessoryRopesRaggedRobesImage,
        },
        {
            value: 7,
            label: "Halo - Glow",
            frontSrc: glowHaloTopImage,
            src: glowHaloImage,
        },
        {
            value: 8,
            label: "Staff - Glow",
            frontSrc: glowStaffImage,
        },
        {
            value: 9,
            label: "Talismans - Glow",
            frontSrc: glowTalismansImage,
        },
    ],
    partList: [
        {
            label: "All",
            value: "ALL",
        },
        {
            label: "Skin Tone",
            value: "BODY",
        },
        {
            label: "All Hair",
            value: "ALLHAIR",
        },
        {
            label: "Accessories",
            value: "ACCESSORIES",
        },
        {
            label: "Body",
            value: "skin",
        },
        {
            label: "Face",
            value: "face",
        },
        // {
        //     label: "Eyes",
        //     value: "eyes",
        //     isGlow: true,
        // },
        {
            label: "Hair",
            value: "hair",
        },
        {
            label: "Facial Hair",
            value: "facialHair",
        },
        {
            label: "Head",
            value: "head",
        },
        {
            label: "Robes",
            value: "chest",
        },
        {
            label: "Feet",
            value: "legs",
        },
        {
            label: "Arms",
            value: "leftArm",
        },
        {
            label: "Back",
            value: "rightArm",
        },
        {
            label: "Weapon",
            value: "leftWeapon",
        },
        {
            label: "Accessory 1",
            value: "accessory1",
        },
        {
            label: "Accessory 2",
            value: "accessory2",
        },
        {
            label: "Glow 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "Glow 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "skin": "Body",
        "face": "Face",
        "eyes": "Eyes",
        "hair": "Hair",
        "facialHair": "Facial Hair",
        "head": "Head",
        "chest": "Robes",
        "legs": "Feet",
        "leftArm": "Arms",
        "rightArm": "Back",
        "leftWeapon": "Weapon",
        "rightWeapon": "Empty",
        "accessory1": "Accessory 1",
        "accessory2": "Accessory 2",
        "accessory3": "Glow 1",
        "accessory4": "Glow 2",
    },
    templateList: [
        {
            label: "Buff",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
            )),
        },
        {
            label: "Elven F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "skinValue": "1",
                    "faceValue": "3",
                },
            )),
        },
        {
            label: "Elven M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                    "skinValue": "0",
                    "faceValue": "2",
                },
            )),
        },
        {
            label: "Reptilekin F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "3",
                    "faceValue": "4",
                },
            )),
        },
        {
            label: "Reptilekin M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "2",
                    "faceValue": "4",
                },
            )),
        },
        {
            label: "Lupine F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "5",
                    "faceValue": "5",
                },
            )),
        },
        {
            label: "Lupine M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "4",
                    "faceValue": "5",
                },
            )),
        },
        {
            label: "Feline F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "skinValue": "7",
                    "faceValue": "16",
                },
            )),
        },
        {
            label: "Feline M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                    "skinValue": "6",
                    "faceValue": "15",
                },
            )),
        },
        {
            label: "Vulpine F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "5",
                    "faceValue": "6",
                },
            )),
        },
        {
            label: "Vulpine M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "4",
                    "faceValue": "6",
                },
            )),
        },
        {
            label: "Fennec F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "5",
                    "faceValue": "8",
                },
            )),
        },
        {
            label: "Fennec M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "4",
                    "faceValue": "8",
                },
            )),
        },
        {
            label: "Lepus F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "1",
                    "faceValue": "7",
                },
            )),
        },
        {
            label: "Lepus M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "0",
                    "faceValue": "7",
                },
            )),
        },
        {
            label: "Owl F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "5",
                    "faceValue": "9",
                    "leftArmValue": "6",
                    "legsValue": "3",
                },
            )),
        },
        {
            label: "Owl M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "4",
                    "faceValue": "9",
                    "leftArmValue": "6",
                    "legsValue": "3",
                },
            )),
        },
        {
            label: "Wednesday F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "1",
                    "faceValue": "10",
                },
            )),
        },
        {
            label: "Wednesday M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "0",
                    "faceValue": "10",
                },
            )),
        },
        {
            label: "Xixo",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "faceValue": "11",
                    "leftArmValue": "4",
                    "legsValue": "3",
                },
            )),
        },
        {
            label: "Trogg F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "16",
                    "skinValue": "1",
                    "faceValue": "1",
                    "headValue": "0",
                },
            )),
        },
        {
            label: "Trogg M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "16",
                    "facialHairValue": "RANDOM",
                    "skinValue": "0",
                    "faceValue": "0",
                    "headValue": "0",
                },
            )),
        },
        {
            label: "Big Trouble",
            isPremium: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "0",
                    "faceValue": "0",
                    "headValue": "6",
                    "chestValue": "1",
                    "leftArmValue": "3",
                    "legsValue": "2",
                },
            )),
        },
    ],
};
