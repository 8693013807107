import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/SSC/Viceroy`;

// BODY
const bodyImage = `${imagePath}/Chassis.png`;

// HEADS
const headSSCViceroyImage = `${imagePath}/heads/Head - SSC Viceroy.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCockpitImage = `${imagePath}/heads/Head - Cockpit.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDaBombImage = `${imagePath}/heads/Head - Da Bomb.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull 3.png`;
const headSlipstreamImage = `${imagePath}/heads/Head - Slipstream.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
const weaponATGrenadesImage = `${imagePath}/weapons/Weapons - AT Grenades.png`;
const weaponBombsImage = `${imagePath}/weapons/Weapons - Bombs.png`;
const weaponLauncherImage = `${imagePath}/weapons/Weapons - Launcher.png`;
const weaponDualLaunchersImage = `${imagePath}/weapons/Weapons - Dual Launchers.png`;
const weaponDualQuadLaunchersImage = `${imagePath}/weapons/Weapons - Dual Quad Launchers.png`;
const weaponHandsImage = `${imagePath}/weapons/Weapons - Hands.png`;
const weaponMissilePodsImage = `${imagePath}/weapons/Weapons - Missile Pods.png`;
const weaponWristMissileRacksImage = `${imagePath}/weapons/Weapons - Wrist Missile Racks.png`;

// SHOULDERS
const shoulderBarrelPodsQuadsImage = `${imagePath}/rearmount/Shoulders - Barrel Pods Quads.png`;
const shoulderLargeMissileRacksImage = `${imagePath}/rearmount/Shoulders - Large Missile Racks.png`;
const shoulderMissilePods32Image = `${imagePath}/rearmount/Shoulders - Missile Pods 32.png`;
const shoulderMissilePods80Image = `${imagePath}/rearmount/Shoulders - Missile Pods 80.png`;
const shoulderMissilePods86Image = `${imagePath}/rearmount/Shoulders - Missile Pods 86.png`;
const shoulderMissilePods134Image = `${imagePath}/rearmount/Shoulders - Missile Pods 134.png`;
const shoulderRamjetsImage = `${imagePath}/rearmount/Shoulders - Ramjets.png`;
const shoulderViceroyShouldersOpenImage = `${imagePath}/rearmount/Shoulders - Viceroy Shoulders Open.png`;

// REAR MOUNT
const rearMountJetpackImage = `${imagePath}/accessories/Accessories Under - Viceroy Jetpack.png`;
const rearMountJetpacks2Image = `${imagePath}/accessories/Accessories Under - Jetpacks 2.png`;
const rearMountJetpacks3Image = `${imagePath}/accessories/Accessories Under - Jetpacks 3.png`;
const rearMountJetpacks4Image = `${imagePath}/accessories/Accessories Under - Jetpacks 4.png`;
const rearMountViceroyJetpackMissilePodsImage = `${imagePath}/accessories/Accessories Under - Viceroy Jetpack Missile Pods.png`;

// ACCESSORIES
const accessoryAllBodyLaunchersImage = `${imagePath}/accessories/Accessories - All Body Launchers.png`;
const accessoryAllBodynoshouldersImage = `${imagePath}/accessories/Accessories - All Body no shoulders.png`;
const accessoryArmmissilesImage = `${imagePath}/accessories/Accessories - Arm missiles.png`;
const accessoryBombpronImage = `${imagePath}/accessories/Accessories - Bombpron.png`;
const accessoryChainsMedalsImage = `${imagePath}/accessories/Accessories - Chains Medals.png`;
const accessoryChestMissilesImage = `${imagePath}/accessories/Accessories - Chest Missiles.png`;
const accessoryCloackShieldImage = `${imagePath}/accessories/Accessories Under - Cloack Shield.png`;
const accessoryHeelsImage = `${imagePath}/accessories/Accessories - Heels.png`;
const accessoryHipMissilesImage = `${imagePath}/accessories/Accessories - Hip Missiles.png`;
const accessoryLegMissilesImage = `${imagePath}/accessories/Accessories - Leg Missiles.png`;
const accessorySweetKicksImage = `${imagePath}/accessories/Accessories - Sweet Kicks.png`;

// GLOW
const glowBodyImage = `${imagePath}/glows/Glow - Body.png`;
const glowBodyViceroyJetsImage = `${imagePath}/glows/Glow - Body Viceroy Jets.png`;
const glowBodyJetsImage = `${imagePath}/glows/Glow - Body Jets.png`;
const glowBodyAllLaunchersImage = `${imagePath}/glows/Glow - Body All Launchers.png`;
const glowBodyAllLaunchersJetsImage = `${imagePath}/glows/Glow - Body All Launchers Jets.png`;
const glowJetpacks2Image = `${imagePath}/glows/Glow - Jetpacks 2.png`;
const glowUnderJetpack2Image = `${imagePath}/glows/Glow Under - Jetpack 2.png`;
const glowJetpacks3Image = `${imagePath}/glows/Glow - Jetpacks 3.png`;
const glowUnderJetpack3Image = `${imagePath}/glows/Glow Under - Jetpack 3.png`;
const glowJetpacks4Image = `${imagePath}/glows/Glow - Jetpacks 4.png`;
const glowUnderJetpack4Image = `${imagePath}/glows/Glow Under - Jetpack 4.png`;
const glowRamjetsImage = `${imagePath}/glows/Glow - Ramjets.png`;
const glowUnderRamjetsImage = `${imagePath}/glows/Glow Under - Ramjets.png`;
const glowViceroyJetpackImage = `${imagePath}/glows/Glow - Viceroy Jetpack.png`;
const glowUnderViceroyJetpackImage = `${imagePath}/glows/Glow Under - Viceroy Jetpack.png`;

const coreImage = `${imagePath}/core.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "57px",
    right: "39px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Fleurdeblanche",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "4",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "7",
        "accessory2Value": "NONE",
        "accessory3Value": "12",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label:"SSC VICEROY",
            foremostSrc: headSSCViceroyImage,
        },
        {
            value: 1,
            label:"8 BALL",
            foremostSrc: head8BallImage,
        },
        {
            value: 2,
            label:"BLACKSPOT",
            foremostSrc: headBlackspotImage,
        },
        {
            value: 3,
            label:"CALIBAN",
            foremostSrc: headCalibanImage,
        },
        {
            value: 4,
            label:"CHEDDAH",
            foremostSrc: headCheddahImage,
        },
        {
            value: 5,
            label:"CHIMERA",
            foremostSrc: headChimeraImage,
        },
        {
            value: 6,
            label:"COCKPIT",
            foremostSrc: headCockpitImage,
        },
        {
            value: 7,
            label:"CYCLOPS",
            foremostSrc: headCyclopsImage,
        },
        {
            value: 8,
            label:"DA BOMB",
            foremostSrc: headDaBombImage,
        },
        {
            value: 9,
            label:"DUNGAM",
            foremostSrc: headDungamImage,
        },
        {
            value: 10,
            label:"HOOD",
            foremostSrc: headHoodImage,
        },
        {
            value: 11,
            label:"HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 12,
            label:"HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 13,
            label:"KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 14,
            label:"LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 15,
            label:"MAG",
            foremostSrc: headMagImage,
        },
        {
            value: 16,
            label:"MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 17,
            label:"MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 18,
            label:"MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 19,
            label:"MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 20,
            label:"MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 21,
            label:"MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 22,
            label:"MECHA 6",
            foremostSrc: headMecha6Image,
        },
        {
            value: 23,
            label:"MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 24,
            label:"MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 25,
            label:"NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 26,
            label:"NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 27,
            label:"NELSON",
            foremostSrc: headNelsonImage,
        },
        {
            value: 28,
            label:"NEMESIS",
            foremostSrc: headNemesisImage,
        },
        {
            value: 29,
            label:"RAIN",
            foremostSrc: headRainImage,
        },
        {
            value: 30,
            label:"RAIN HAIR",
            foremostSrc: headRainHairImage,
        },
        {
            value: 31,
            label:"SKULL",
            foremostSrc: headSkullImage,
        },
        {
            value: 32,
            label:"SLIPSTREAM",
            foremostSrc: headSlipstreamImage,
        },
        {
            value: 33,
            label:"TOKUGAWA",
            foremostSrc: headTokugawaImage,
        },
        {
            value: 34,
            label:"VANQUISHER",
            foremostSrc: headVanquisherImage,
        },
        {
            value: 35,
            label:"VICEROY",
            foremostSrc: headViceroyImage,
        },
        {
            value: 36,
            label:"VISIONARY",
            foremostSrc: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "VICEROY",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AT GRENADES",
                src: weaponATGrenadesImage,
            },
            {
                value: 1,
                label: "BOMBS",
                src: weaponBombsImage,
            },
            {
                value: 2,
                label: "DUAL LAUNCHERS",
                src: weaponDualLaunchersImage,
            },
            {
                value: 3,
                label: "DUAL QUAD LAUNCHERS",
                src: weaponDualQuadLaunchersImage,
            },
            {
                value: 4,
                label: "HANDS",
                src: weaponHandsImage,
            },
            {
                value: 5,
                label: "LAUNCHER",
                src: weaponLauncherImage,
            },
            {
                value: 6,
                label: "MISSILE PODS",
                src: weaponMissilePodsImage,
            },
            {
                value: 7,
                label: "WRIST MISSILE RACKS",
                src: weaponWristMissileRacksImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BARREL PODS QUADS",
            src: shoulderBarrelPodsQuadsImage,
        },
        {
            value: 1,
            label: "LARGE MISSILE RACKS",
            src: shoulderLargeMissileRacksImage,
        },
        {
            value: 2,
            label: "MISSILE PODS 32",
            src: shoulderMissilePods32Image,
        },
        {
            value: 3,
            label: "MISSILE PODS 80",
            src: shoulderMissilePods80Image,
        },
        {
            value: 4,
            label: "MISSILE PODS 86",
            src: shoulderMissilePods86Image,
        },
        {
            value: 5,
            label: "MISSILE PODS 134",
            src: shoulderMissilePods134Image,
        },
        {
            value: 6,
            label: "VICEROY SHOULDERS OPEN",
            src: shoulderViceroyShouldersOpenImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "VICEROY JETPACK",
            src: rearMountJetpackImage,
        },
        {
            value: 1,
            label: "VICEROY JETPACK + MISSILE PODS",
            src: rearMountViceroyJetpackMissilePodsImage,
        },
        {
            value: 2,
            label: "JETPACK 2",
            src: rearMountJetpacks2Image,
        },
        {
            value: 3,
            label: "JETPACK 3",
            src: rearMountJetpacks3Image,
        },
        {
            value: 4,
            label: "JETPACK 4",
            src: rearMountJetpacks4Image,
        },
        {
            value: 5,
            label: "RAMJETS",
            src: shoulderRamjetsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "ARM MISSILES",
            src: accessoryArmmissilesImage,
        },
        {
            value: 1,
            label: "CHEST MISSILES",
            src: accessoryChestMissilesImage,
        },
        {
            value: 2,
            label: "HIP MISSILES",
            src: accessoryHipMissilesImage,
        },
        {
            value: 3,
            label: "LEG MISSILES",
            src: accessoryLegMissilesImage,
        },
        {
            value: 4,
            label: "ALL BODY MISSILES",
            src: accessoryAllBodyLaunchersImage,
        },
        {
            value: 5,
            label: "ALL BODY MISSILES - NO SHOULDERS",
            src: accessoryAllBodynoshouldersImage,
        },
        {
            value: 6,
            label: "BOMBPRON",
            src: accessoryBombpronImage,
        },
        {
            value: 7,
            label: "CLOAK + SHIELD",
            frontSrc: accessoryChainsMedalsImage,
            backSrc: accessoryCloackShieldImage,
        },
        {
            value: 8,
            label: "HEELS",
            src: accessoryHeelsImage,
        },
        {
            value: 9,
            label: "SWEET KICKS",
            src: accessorySweetKicksImage,
        },
        {
            value: 10,
            label: "GLOW - BODY",
            src: glowBodyImage,
        },
        {
            value: 11,
            label: "GLOW - BODY + RAMJETS",
            src: glowBodyJetsImage,
            backSrc: glowUnderRamjetsImage,
        },
        {
            value: 12,
            label: "GLOW - BODY + VICEROY JETPACK",
            src: glowBodyViceroyJetsImage,
            backSrc: glowUnderViceroyJetpackImage,
        },
        {
            value: 13,
            label: "GLOW - ALL BODY MISSILES",
            frontSrc: glowBodyAllLaunchersImage,
        },
        {
            value: 14,
            label: "GLOW - BODY + ALL MISSILES",
            frontSrc: glowBodyAllLaunchersJetsImage,
        },
        {
            value: 15,
            label: "GLOW - VICEROY JETPACK",
            src: glowViceroyJetpackImage,
            backSrc: glowUnderViceroyJetpackImage,
        },
        {
            value: 16,
            label: "GLOW - JETPACK 2",
            src: glowJetpacks2Image,
            backsrc: glowUnderJetpack2Image,
        },
        {
            value: 17,
            label: "GLOW - JETPACK 3",
            src: glowJetpacks3Image,
            backSrc: glowUnderJetpack3Image,
        },
        {
            value: 18,
            label: "GLOW - JETPACK 4",
            src: glowJetpacks4Image,
            backSrc: glowUnderJetpack4Image,
        },
        {
            value: 19,
            label: "GLOW - RAMJETS",
            src: glowRamjetsImage,
            backSrc: glowUnderRamjetsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPONS",
            value: "weapon",
        },
        {
            label: "SHOULDERS",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPONS",
        "secondaryWeapon": "SHOULDERS",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
