import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/DFG-Acedia`;

// BODY
const bodyChassisImage = `${imagePath}/Chassis.png`;

// HEADS
const headAcediaImage = `${imagePath}/heads/Head - Acedia.png`;
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headBarbarossaImage = `${imagePath}/heads/Head - Barbarossa.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headHackingArrayImage = `${imagePath}/heads/Head - Hacking Array.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headTortugaImage = `${imagePath}/heads/Head - Tortuga.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
const weaponAntiMaterielRifleImage = `${imagePath}/weapons/Weapons - Anti-Materiel Rifle.png`;
const weaponAssaultRifle1Image = `${imagePath}/weapons/Weapons - Assault Rifle 1.png`;
const weaponAssaultRifle2Image = `${imagePath}/weapons/Weapons - Assault Rifle 2.png`;
const weaponDecksweeperImage = `${imagePath}/weapons/Weapons - Decksweeper.png`;
const weaponFORCECannonImage = `${imagePath}/weapons/Weapons - FORCE Cannon.png`;
const weaponGatlingCannonImage = `${imagePath}/weapons/Weapons - Gatling Cannon.png`;
const weaponHMGImage = `${imagePath}/weapons/Weapons - HMG.png`;
const weaponRailgunTripodImage = `${imagePath}/weapons/Weapons - Railgun Tripod.png`;
const weaponRailgunImage = `${imagePath}/weapons/Weapons - Railgun.png`;
const weaponSPAS13Image = `${imagePath}/weapons/Weapons - SPAS-13.png`;
const weaponSunriseCannonImage = `${imagePath}/weapons/Weapons - Sunrise Cannon.png`;

// SHOULDERS
const shoulderBracketingBombletsLImage = `${imagePath}/shoulders/Shoulders - Bracketing Bomblets L.png`;
const shoulderBracketingBombletsRImage = `${imagePath}/shoulders/Shoulders - Bracketing Bomblets R.png`;
const shoulderBracketingBombletsBothImage = `${imagePath}/shoulders/Shoulders - Bracketing Bomblets Both.png`;
const shoulderCannonLImage = `${imagePath}/shoulders/Shoulders - Cannon L.png`;
const shoulderCannonRImage = `${imagePath}/shoulders/Shoulders - Cannon R.png`;
const shoulderCannonBothImage = `${imagePath}/shoulders/Shoulders - Cannon Both.png`;
const shoulderGatlingAmmoDrumImage = `${imagePath}/shoulders/Shoulders - Gatling Ammo Drum.png`;
const shoulderHeatsinksLImage = `${imagePath}/shoulders/Shoulders - Heatsinks L.png`;
const shoulderHeatsinksRImage = `${imagePath}/shoulders/Shoulders - Heatsinks R.png`;
const shoulderHeatsinksBothImage = `${imagePath}/shoulders/Shoulders - Heatsinks Both.png`;
const shoulderProximityDefenceCannonLImage = `${imagePath}/shoulders/Shoulders - Proximity Defence Cannon L.png`;
const shoulderProximityDefenceCannonRImage = `${imagePath}/shoulders/Shoulders - Proximity Defence Cannon R.png`;
const shoulderProximityDefenceCannonBothImage = `${imagePath}/shoulders/Shoulders - Proximity Defence Cannon Both.png`;

// GLOWS
const glowHackingArrayImage = `${imagePath}/glows/Chassis Glow - Hacking Array.png`;
const glowHackingArrayHeadlessImage = `${imagePath}/glows/Hacking Glow headless.png`;
const glowHeatsinkLImage = `${imagePath}/glows/Chassis Glow - Heatsink L.png`;
const glowHeatsinkRImage = `${imagePath}/glows/Chassis Glow - Heatsink R.png`;
const glowHeatsinkBothImage = `${imagePath}/glows/Chassis Glow - Heatsink Both.png`;
const glowRailgunCannonImage = `${imagePath}/glows/Weapon Glow - Railgun Cannon.png`;
const glowSunriseCannonImage = `${imagePath}/glows/Weapon Glow - Sunrise Cannon.png`;

// ACCESSORIES
const accessoryBarbarossaChestImage = `${imagePath}/accessories/Chassis Accessories - Barbarossa Chest.png`;
const accessoryCloakImage = `${imagePath}/accessories/Chassis Accessories - Cloak.png`;
const accessoryHackingArrayImage = `${imagePath}/accessories/Chassis Accessories - Hacking Array.png`;
const accessoryShieldImage = `${imagePath}/accessories/Chassis Accessories - Shield.png`;
const accessoryStrappedImage = `${imagePath}/accessories/Chassis Accessories - Strapped.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/Acedia_core-Kottadragon.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "36px",
    right: "-4px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Kottadragon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "4",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ACEDIA",
            src: headAcediaImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 2,
            label: "BARBAROSSA",
            src: headBarbarossaImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 6,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 7,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 8,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 9,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 10,
            label: "HACKING ARRAY",
            src: headHackingArrayImage,
        },
        {
            value: 11,
            label: "HOOD",
            src: headHoodImage,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 13,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 14,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 15,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 16,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 17,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 18,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 19,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 20,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 21,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 22,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 23,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 24,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 25,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 26,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 27,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 28,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 29,
            label: "TORTUGA",
            src: headTortugaImage,
        },
        {
            value: 30,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DFG ACEDIA",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "RAILGUN CANNON",
                foremostSrc: glowRailgunCannonImage,
            },
            {
                value: 1,
                label: "SUNRISE CANNON",
                foremostSrc: glowSunriseCannonImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ANTI-MATERIEL RIFLE",
            foremostSrc: weaponAntiMaterielRifleImage,
        },
        {
            value: 1,
            label: "ASSAULT RIFLE 1",
            foremostSrc: weaponAssaultRifle1Image,
        },
        {
            value: 2,
            label: "ASSAULT RIFLE 2",
            foremostSrc: weaponAssaultRifle2Image,
        },
        {
            value: 3,
            label: "DECKSWEEPER",
            foremostSrc: weaponDecksweeperImage,
        },
        {
            value: 4,
            label: "FORCE CANNON",
            foremostSrc: weaponFORCECannonImage,
        },
        {
            value: 5,
            label: "GATLING CANNON",
            foremostSrc: weaponGatlingCannonImage,
        },
        {
            value: 6,
            label: "HMG",
            foremostSrc: weaponHMGImage,
        },
        {
            value: 7,
            label: "RAILGUN",
            foremostSrc: weaponRailgunImage,
        },
        {
            value: 8,
            label: "RAILGUN TRIPOD",
            foremostSrc: weaponRailgunTripodImage,
        },
        {
            value: 9,
            label: "SPAS-13",
            foremostSrc: weaponSPAS13Image,
        },
        {
            value: 10,
            label: "SUNRISE CANNON",
            foremostSrc: weaponSunriseCannonImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "BARBAROSSA CHEST",
            frontSrc: accessoryBarbarossaChestImage,
        },
        {
            value: 1,
            label: "CLOAK",
            frontSrc: accessoryCloakImage,
        },
        {
            value: 2,
            label: "HACKING ARRAY",
            frontSrc: accessoryHackingArrayImage,
        },
        {
            value: 3,
            label: "SHIELD",
            frontSrc: accessoryShieldImage,
        },
        {
            value: 4,
            label: "STRAPPED",
            frontSrc: accessoryStrappedImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BRACKETING BOMBLETS L",
            src: shoulderBracketingBombletsLImage,
        },
        {
            value: 1,
            label: "BRACKETING BOMBLETS R",
            src: shoulderBracketingBombletsRImage,
        },
        {
            value: 2,
            label: "BRACKETING BOMBLETS BOTH",
            src: shoulderBracketingBombletsBothImage,
        },
        {
            value: 3,
            label: "CANNON L",
            src: shoulderCannonLImage,
        },
        {
            value: 4,
            label: "CANNON R",
            src: shoulderCannonRImage,
        },
        {
            value: 5,
            label: "CANNON BOTH",
            src: shoulderCannonBothImage,
        },
        {
            value: 6,
            label: "GATLING AMMO DRUM",
            src: shoulderGatlingAmmoDrumImage,
        },
        {
            value: 7,
            label: "HEATSINKS L",
            src: shoulderHeatsinksLImage,
        },
        {
            value: 8,
            label: "HEATSINKS R",
            src: shoulderHeatsinksRImage,
        },
        {
            value: 9,
            label: "HEATSINKS BOTH",
            src: shoulderHeatsinksBothImage,
        },
        {
            value: 10,
            label: "PROXIMITY DEFENCE CANNON L",
            src: shoulderProximityDefenceCannonLImage,
        },
        {
            value: 11,
            label: "PROXIMITY DEFENCE CANNON R",
            src: shoulderProximityDefenceCannonRImage,
        },
        {
            value: 12,
            label: "PROXIMITY DEFENCE CANNON BOTH",
            src: shoulderProximityDefenceCannonBothImage,
        },
        {
            value: 13,
            label: "GLOW - HACKING ARRAY",
            frontSrc: glowHackingArrayImage,
        },
        {
            value: 14,
            label: "GLOW - HACKING ARRAY - HEADLESS",
            src: glowHackingArrayHeadlessImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "Kottadragon",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 15,
            label: "GLOW - HEATSINK L",
            frontSrc: glowHeatsinkLImage,
        },
        {
            value: 16,
            label: "GLOW - HEATSINK R",
            frontSrc: glowHeatsinkRImage,
        },
        {
            value: 17,
            label: "GLOW - HEATSINK BOTH",
            frontSrc: glowHeatsinkBothImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "SHOULDERS / GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON GLOW",
            value: "weapon",
            isGlow: true,
        },
        {
            label: "WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY",
            value: "rearMount",
        },
        {
            label: "SHOULDER 1",
            value: "accessory1",
        },
        {
            label: "SHOULDER 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ACCESSORY",
        "weapon": "WEAPON GLOW",
        "secondaryWeapon": "WEAPON",
        "accessory1": "SHOULDER 1",
        "accessory2": "SHOULDER 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
